import { handleRecordNotes } from './global';

const pricingOptions = {
  handling: 'NORM',
  numLic: 0,
  iCode1: '',
  iCode2: '',
  hub: ''
};

export function quoteSelected(self) {
  const lcDocHdl = self.getValue('selfhdl');
  const oSDO = self.container.getObject('dbomlines');
  oSDO.controller.setSrvProp('setDocHdl', lcDocHdl);
  oSDO.controller.reloadData();
}

function calculateAmount(oSelf) {
  const oGrid = oSelf.parent;
  const eQty = oGrid.getValue('qty') * oGrid.getValue('quantity');
  const eAmount = eQty * oGrid.getValue('vk1');
  oGrid.setValue('amount', eAmount);
  return;
}

export function qtyLeave(self, cOptions) {
  const cItem = self.parent.getValue('artikel-nr');
  if (cItem == '') {
    self.parent.setValue('qty', 0);
    return;
  }

  if (self.getValue() == 0) {
    const ePrice = self.parent.getValue('vk1');
    if (ePrice != 0) {
      self.parent.setValue('vk1', 0);
      calculatePrice(self, cOptions);
      return;
    }
  }

  handleRecordNotes(cItem);

  const oHandling = app.controller.callServerMethod('stubs/getitemhandling.p', [
    { type: 'iCHAR', value: cItem },
    { type: 'iCHAR', value: 'ERPItem' },
    { type: 'oCHAR', name: 'cReturn' }
  ]);

  pricingOptions.handling = oHandling.cReturn;

  switch (oHandling.cReturn) {
    case 'CONF':
      akioma.message({
        text: 'Identcode1:', type: 'input', callback: code1 => {
          pricingOptions.iCode1 = code1;
          akioma.message({
            text: 'Identcode2:', type: 'input', callback: code2 => {
              pricingOptions.iCode2 = code2;
              calculatePrice(self, cOptions);
            }
          });
        }
      });
      break;
    case 'LIC':
      akioma.message({
        text: 'Anzahl Lizenzen:', type: 'input', inputVal: 1, callback: numLic => {
          pricingOptions.numLic = numLic;
          pricingOptions.hub = `LICENCES_${pricingOptions.numLic}PCS`;
          calculatePrice(self, cOptions);
        }
      });
      break;
    case 'HUB':
      akioma.message({
        text: 'Länge:', type: 'input', inputVal: 1, callback: numLic => {
          pricingOptions.numLic = numLic;
          pricingOptions.hub = `${pricingOptions.numLic}`;
          calculatePrice(self, cOptions);
        }
      });
      break;
    default:
      calculatePrice(self, cOptions);
      break;
  }
}

function calculatePrice(oSelf) {
  const oForm = oSelf.parent;
  const lcOfferHdl = getMyValue(oSelf, 'selfhdl');
  const cDelim = String.fromCharCode(9);
  const lcBomLineHdl = oForm.getValue('selfhdl');

  let cItemKey = oForm.getValue('artikel-nr');
  if (pricingOptions.handling != 'NORM')
    cItemKey += cDelim + pricingOptions.hub + cDelim + pricingOptions.iCode1 + cDelim + pricingOptions.iCode2;


  const oReturn = app.controller.callServerMethod('stubs/getSalesPriceQuote.p', [

    { type: 'iCHAR', value: lcOfferHdl },
    { type: 'iCHAR', value: lcBomLineHdl },
    { type: 'iCHAR', value: '' }, /* Customer */
    { type: 'iCHAR', value: cItemKey },
    { type: 'iINT', value: 0 }, /* PriceGroup */
    { type: 'iCHAR', value: '' }, /* Currency */
    { type: 'iDATE', value: null }, /* Sales Date */
    { type: 'iDEC', value: oForm.getValue('qty') },
    { type: 'iCHAR', value: '' }, /* SalesUnit */
    { type: 'oDEC', name: 'ePrice' },
    { type: 'oDEC', name: 'eTaxRate' },
    { type: 'oCHAR', name: 'cError' },
    { type: 'oCHAR', name: 'cInfo' }
  ]);

  if (oReturn.cError != '')
    akioma.showServerMessage(oReturn.cError);

  app.sessionData.lastQuote = oForm.name;

  oForm.setValue('vk1', oReturn.ePrice);

  calculateAmount(oSelf, '');
}

export function saveQuoteLines(oSelf) {
  const lcOfferHdl = getMyValue(oSelf, 'selfhdl');

  const oCaller = oSelf;
  if (isValidObj(oCaller) == false) {
    akioma.log.error(`cannot get container for: ${oSelf.name}`);
    return '';
  }

  let oNavTarget = oCaller.getLink('TABLEIO:TARGET');
  if (isValidObj(oNavTarget) == false) {
    akioma.log.error(`cannot get NAVIGATION-SDO Target for: ${oCaller.name} (${oCaller.type})`);
    return '';
  }

  oNavTarget = oNavTarget.getLink('DATA:SOURCE');
  oNavTarget.controller.setSrvProp('setDocHdl', lcOfferHdl);

  const oReturn = app.controller.callServerMethod('mod/offer/saveQuoteLines.p', [
    { type: 'iCHAR', value: lcOfferHdl },
    { type: 'iCHAR', value: '' },
    { type: 'oCHAR', name: 'cStatus' },
    { type: 'oCHAR', name: 'cResult' }
  ]);

  akioma.messaging.info (`Fertig. </br>${oReturn.cStatus}</br>` + `Eregebnis: ${oReturn.cResult}`);
}

export function clearQuoteLines(oSelf) {
  const lcOfferHdl = getMyValue(oSelf, 'selfhdl');

  const oCaller = oSelf;
  if (isValidObj(oCaller) == false) {
    akioma.log.error(`cannot get container for: ${oSelf.name}`);
    return '';
  }

  let oNavTarget = oCaller.getLink('TABLEIO:TARGET');
  if (isValidObj(oNavTarget) == false) {
    akioma.log.error(`cannot get NAVIGATION-SDO Target for: ${oCaller.name} (${oCaller.type})`);
    return '';
  }

  oNavTarget = oNavTarget.getLink('DATA:SOURCE');
  oNavTarget.controller.setSrvProp('setDocHdl', lcOfferHdl);

  const oReturn = app.controller.callServerMethod('mod/offer/clearQuoteLines.p', [
    { type: 'iCHAR', value: lcOfferHdl },
    { type: 'iCHAR', value: '' },
    { type: 'oCHAR', name: 'cStatus' },
    { type: 'oCHAR', name: 'cResult' }
  ]);

  akioma.messaging.info (`Fertig. </br>${oReturn.cStatus}</br>` + `Eregebnis: ${oReturn.cResult}`);
}

export function getDocumentHdl(oSelf, cFieldName) {
  const oCaller = oSelf.container;
  if (isValidObj(oCaller) == false) {
    akioma.log.error(`cannot get container for: ${oSelf.name}`);
    return '';
  }

  const oNavTarget = oCaller.getLink('PRIMARYSDO:TARGET');
  if (isValidObj(oNavTarget) == false) {
    akioma.log.error(`cannot get Primary-SDO Target for: ${oCaller.name} (${oCaller.type})`);
    return '';
  }

  return oNavTarget.getValue(cFieldName);
}

export function newDlg_leave(self) {
  if (self.name == 'customerhdl') {
    const cCustHdl = self.getValue();
    app.controller.callServerMethod('stubs/getOfferInitValues.p', [
      { type: 'iCHAR', value: cCustHdl },
      { type: 'iCHAR', value: '' },
      { type: 'oCHAR', name: 'cPriceGroup' },
      { type: 'oCHAR', name: 'cCurrency' },
      { type: 'oCHAR', name: 'cStatus' }
    ]);
  }
}

export function newDlg_Init(self) {
  const oViewer = self.container.getObject('quotenewv');
  const oField = oViewer.getField('customerhdl');

  const oSDO = self.container.caller.parent.getLink('PRIMARYSDO:TARGET');
  if (isValidObj(oSDO) == false) {
    akioma.log.error(`cannot get Primary-SDO Target for: ${self.container.caller.name}`);
    return '';
  }

  const lcOfferHdl = oSDO.getValue('selfhdl');
  const oReturn = app.controller.callServerMethod('stubs/getCustOfOffer.p', [
    { type: 'iCHAR', value: lcOfferHdl },
    { type: 'oCHAR', name: 'cCustHdl' },
    { type: 'oCHAR', name: 'cContactHdl' }
  ]);
  oField.controller.setValueKey(oReturn.cCustHdl);
  oField.controller.getValueFromServer(oReturn.cCustHdl);
}

export function newDlg_OK(self) {
  const cMode = self.container.getValue('gcmode');
  const oReturn = app.controller.callServerMethod('stubs/createoffer2.p', [
    { type: 'iCHAR', value: 'createQuote' },
    { type: 'iTT', value: self.getAllValues() },
    { type: 'oCHAR', name: 'creturn' }
  ]);
  if (isValidHdl(oReturn.creturn) == false) {
    akioma.log.error(`Fehler in Preisanfragen-Neuanlage: ${oReturn.creturn}`);
    return;
  }

  const oNewContainer = app.controller.launchContainer({
    proc: 'opq2w.r',
    para: `SelfHdl=${oReturn.creturn}&Page=0,1`,
    extLink: oReturn.creturn,
    self: this,
    data: true
  });
  if (cMode == 'CM' || cMode == 'CM2') {
    const cSourceHdl = self.container.getValue('sourcehdl');
    self.container.controller.close();

    oNewContainer.posWindow('left');
    app.controller.launchContainer({
      proc: 'opq2w.r',
      para: `SelfHdl=${cSourceHdl}&Page=0,1`,
      extLink: cSourceHdl,
      position: 'fitToRight',
      self: this,
      data: true
    });
  } else
    self.container.controller.close();
}

export function calculateAmountDummy(oSelf, cOptions) {
  calculateAmount (oSelf, cOptions);
  return;
}

function getMyValue(oSelf, cFieldName) {
  const oCaller = oSelf.container;
  if (isValidObj(oCaller) == false) {
    akioma.log.error(`cannot get container for: ${oSelf.name}`);
    return '';
  }

  const oNavTarget = oCaller.getLink('PRIMARYSDO:TARGET');
  if (isValidObj(oNavTarget) == false) {
    akioma.log.error(`cannot get Primary-SDO Target for: ${oCaller.name} (${oCaller.type})`);
    return '';
  }

  return oNavTarget.getValue(cFieldName);
}
