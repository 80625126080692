export function onEventDataAvailableBE(oSelf) {
  const cRecHdl = oSelf.parent.getObject('dsumpos').controller.getIndex();
  if (isValidHdl(cRecHdl)) {
    const oForm = oSelf.parent.getObject('sumpos_basedatav'),
      cParentHdl = oSelf.getValue('startposparenthdl'),
      cAkParentHdl = oSelf.getValue('akstructparenthdl'),
      cRefHdl = oSelf.getValue('refhdl');
    oForm.getObject('StartPosDesc').controller.businessEntity.query.addUniqueCondition('context', 'eq', 'StartPosSelect');
    oForm.getObject('StartPosDesc').controller.businessEntity.query.addUniqueCondition('parenthdl', 'eq', cParentHdl);
    oForm.getObject('StartPosDesc').controller.businessEntity.query.addUniqueCondition('akparenthdl', 'eq', cAkParentHdl);
    oForm.getObject('StartPosDesc').controller.businessEntity.query.addUniqueCondition('refhdl', 'eq', cRefHdl);
    oForm.getObject('StartPosDesc').controller.businessEntity.query.setSorting([{ field: 'seqnum', direction: '' }]);
    oForm.getObject('EndPosDesc').controller.businessEntity.query.addUniqueCondition('context', 'eq', 'EndPosSelect');
    oForm.getObject('EndPosDesc').controller.businessEntity.query.addUniqueCondition('parenthdl', 'eq', cParentHdl);
    oForm.getObject('EndPosDesc').controller.businessEntity.query.addUniqueCondition('akparenthdl', 'eq', cAkParentHdl);
    oForm.getObject('EndPosDesc').controller.businessEntity.query.addUniqueCondition('refhdl', 'eq', cRefHdl);
    oForm.getObject('EndPosDesc').controller.businessEntity.query.setSorting([{ field: 'seqnum', direction: '' }]);
  }
}

export function onEventBeforeFetchPosSelect(oSelf, cPairName) {
  oSelf.controller.businessEntity.query.addUniqueCondition('pairhdl', 'eq',
    oSelf.container.getObject('dsumpos').controller.getFieldValue(cPairName));
}
