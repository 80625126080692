/**
 * Method executed after display of a TextModule in the Offer/Product Posframe. Handles Tab visibility inside the PosFrame.
 * @param {Object} self Form dynObject
 */
export function txtPosFrameAfterDisplay(self) {
  this.txtModVisibilityHandling(self);
}

/**
 * Method executed after save of a TextModule in the Offer/Product Posframe. Reloades PosFrame BusinessEntity data.
 * @param {Object} self BusinessEntity dynObject
 */
export function txtPosFrameReloadData(self) {
  self.controller.reloadData();
}

/**
 * Method handling Tab visibility inside the PosFrame for a TextModule.
 * @param {Object} form Form dynObject
 */
export function txtModVisibilityHandling(form) {
  const moduleType = form.getLink('DISPLAY:SRC').getValue('txtmodmoduletype');
  const txtModTypeFrame = form.screen.getObject('txtmodtypef');

  txtModTypeFrame.controller.afterFileInit(() => {
    const tabbar = txtModTypeFrame.getObject('simpleswattabbar').controller;
    if (moduleType == 'word') {
      tabbar.viewPage('word');
      tabbar.setActivePage('word');
      tabbar.hidePage('text');
    } else {
      tabbar.viewPage('text');
      tabbar.setActivePage('text');
      tabbar.hidePage('word');
    }
  });
}

/**
 * Method to trigger the field visibility handling in the text module maintenance screen
 * @param {Object} self
 */
export function textAfterDisplay(self) {
  const textModuleType = self.getObject('moduletype');
  if (!isNull(textModuleType))
    handleFieldsVisibility(self, textModuleType.getValue());

}

/**
 * Method to trigger the field visibility handling in the text module maintenance screen
 * @param {Object} self
 */
export function chooseTextModuleType(self) {
  const textForm = self.screen.getObject('txtmod_headerv');
  handleFieldsVisibility(textForm, self.getValue());
}

/**
 * Method handling field visibility in text module maintenance screen
 * @param {Object} self
 * @param {string} value
 */
function handleFieldsVisibility(self, value) {
  if (value == 'word')
    self.controller.setVisible('textdocument', true);
  else
    self.controller.setVisible('textdocument', false);
}

/**
 * Method to launch the external file selection screen for text modules
 * @param {Object} self
 */
export function chooseDocument(self) {
  app.controller.launchContainer({
    containerName: 'chooseFileG',
    data: true,
    self: self,
    customData: {
      callback: '$ akioma.text.afterChooseDocument(self);',
      groupHdl: 'txtMod'
    }
  });
}

/**
 * Method to call on before fetch of txtmod be to filter relations of text modules
 * @param {ak_businessEntity} self
 */
export function beforeFetchRelationsTextModules(self) {
  const dataSource = self.container.getLink('PRIMARYSDO:TARGET').controller;
  const isPendingQueryRequest = dataSource._pendingrequest;

  if (isPendingQueryRequest) {
    dataSource.addAfterFillOnceCallback(() => {
      const RelRecordHdl = self.container.getLink('PRIMARYSDO:TARGET').getValue('selfhdl');
      self.controller.setNamedQueryParam('RelationResult', 'Direction', 'TARGET', 'character');
      self.controller.setNamedQueryParam('RelationResult', 'RelRecordHdl', RelRecordHdl, 'character');
      self.controller.openQuery();
    });
  } else {
    const RelRecordHdl = self.container.getLink('PRIMARYSDO:TARGET').getValue('selfhdl');
    self.controller.setNamedQueryParam('RelationResult', 'Direction', 'TARGET', 'character');
    self.controller.setNamedQueryParam('RelationResult', 'RelRecordHdl', RelRecordHdl, 'character');
  }
}

/**
 * Refresh text module relation dependency from toolbar btn
 * @param {ak_businessEntity} self
 * @return {Promise}
 */
export function textModuleRelRefresh(self) {
  const oDso = self.getLink('TABLEIO:TARGET').getLink('DATA:SRC').controller;
  oDso.openQuery();
}

/**
 * Delete text module relation dependency from toolbar btn
 * @param {ak_businessEntity} self
 * @return {Promise}
 */
export function textModuleRelDelete(self) {
  const oDso = self.getLink('TABLEIO:TARGET').getLink('DATA:SRC');
  return akioma.text.deleteModuleRelation(oDso);
}

/**
 * Method used for create new module relation dependecy screen, foreignkey fields
 */
export function getParentSelfHdl(self) {
  const primarySDOHdl = self.container.getLink('PRIMARYSDO:TARGET').getValue('selfhdl');
  return primarySDOHdl;
}

/**
 * Method for launching TextModuleRelationWindow screen to display
 * all related modules of a given text module that is part of a tree
 * @param {Object} self Ribbon button in the ribbon of a struct treegrid
 * @param {boolean} [lUsePosframe=true]
 */
export function showRelatedModules(self, lUsePosframe = true) {
  const cField = 'txtmodhdl';
  const cWindow = 'TextModuleRelationWindow';
  let cChild = '';

  const oContainer = lUsePosframe ? getActionContainer(self, false) : self.container;
  const oNavTarget = oContainer.getLink('PRIMARYSDO:TARGET');
  if (isValidObj(oNavTarget) == false) {
    akioma.notification({ type: 'error', text: `cannot get Primary-SDO Target for: ${self.name} (${self.type})` });
    return;
  }

  const cHdl = oNavTarget.getValue(akioma.entry(1, cField));
  if (isValidHdl(cHdl) == false) {
    akioma.showServerMessage('Kein gültiger Stammdatensatz.');
    return;
  }

  if (akioma.numEntries(cField) > 1)
    cChild = oNavTarget.getValue(akioma.entry(2, cField));

  app.controller.launchContainer({
    containerName: cWindow,
    data: true,
    repositionTo: cHdl,
    pages: '1,2',
    self: self,
    customData: { childHdl: cChild }
  });
}
