import { newDlg_OK } from './opq';
export { sendDocToERP as doc2erpg_OK } from './offer';

export function tranPack2ChooseW_OK(self) {
  const oSDO = self.parent.getLink('PRIMARYSDO:TARGET');
  if (isValidObj(oSDO) == false) {
    akioma.log.error(`cannot get Primary-SDO Target for: ${self.container.name}`);
    return '';
  }

  const lcHdl = oSDO.getValue('selfhdl');

  const oReturn = app.controller.callServerMethod('std/tran/activateTranPack.p', [
    { type: 'iCHAR', value: lcHdl },
    { type: 'oCHAR', name: 'cStatus' },
    { type: 'oCHAR', name: 'cResult' }
  ]);

  try {
    app.controller.launchContainer({
      proc: 'tranW.r',
      para: `SelfHdl=${lcHdl}&Page=0,1`,
      extLink: lcHdl,
      self: this,
      data: true
    });
  } catch (err) {
    akioma.log.error(`error tranW: ${err.message}`);
  }

  akioma.notification({
    type: 'info',
    text: `${oReturn.cStatus}<br>${oReturn.cResult}`,
    lifetime: 10000
  });
}

export function tranPackW_OK(self) {
  let oTree;
  const oViewer = self.container.getObject('vtranpack');
  let cStructHdl = '';

  const oSDO = self.container.caller.container.getLink('PRIMARYSDO:TARGET');
  if (isValidObj(oSDO) == false) {
    akioma.log.error(`cannot get Primary-SDO Target for: ${self.container.caller.name}`);
    return '';
  }

  let lcBoMHdl = oSDO.getValue('selfhdl');

  try {
    oTree = self.container.caller.container.getObject('itTree');
    if (oTree) {
      cStructHdl = oTree.getValue('selfhdl');
      if (isValidHdl(cStructHdl))
        lcBoMHdl = `${lcBoMHdl},${cStructHdl}`;
    }
  } catch (err) {
    akioma.log.error(`error getting Tree-Node: ${err.message}`);
  }
  oViewer.controller.parent.parent.dhx.progressOn();
  oViewer.controller.parent.dhx.progressOn();

  window.setTimeout(() => {
    const oReturn = app.controller.callServerMethod('std/tran/buildTranList.p', [
      { type: 'iCHAR', value: lcBoMHdl },
      { type: 'iCHAR', value: oViewer.getValue('gcbaselanghdl') },
      { type: 'iCHAR', value: oViewer.getValue('gclanghdl') },
      { type: 'iCHAR', value: oViewer.getValue('gctranowner') },
      { type: 'iCHAR', value: oViewer.getValue('selfdesc') },
      { type: 'iCHAR', value: oViewer.getValue('gcprintscope') },
      { type: 'oCHAR', name: 'cStatus' },
      { type: 'oCHAR', name: 'cResult' },
      { type: 'oINT', name: 'iNumRead' },
      { type: 'oINT', name: 'iNumAdded' }
    ]);

    oViewer.controller.parent.parent.dhx.progressOff();
    oViewer.controller.parent.dhx.progressOff();

    akioma.notification({
      type: 'info',
      text: `${oReturn.cStatus} ${oReturn.cResult}<br>${oReturn.iNumRead} Datensätze gelesen<br>${oReturn.iNumAdded} Datensätze hinzugefügt`,
      lifetime: -1
    });
  }, 5);
}

export function Mailingg_OK(self) {
  const oActivity = self.container.caller.parent.getLink('PRIMARYSDO:TARGET');
  const cActivity = oActivity.getValue('selfhdl');
  const oViewer = self.container.getObject('ChooseFormMailingV');

  const oReturn = app.controller.callServerMethod('stubs/mailmerge.p', [
    { type: 'iCHAR', value: cActivity },
    { type: 'iTT', value: oViewer.getObject('selfkey').getAllValues() },
    { type: 'iCHAR', value: '' },
    { type: 'oCHAR', name: 'cOut' },
    { type: 'oCHAR', name: 'cStatus' }
  ]);

  akioma.messaging.info (`${'Mailing beendet. </br>' + 'Anzahl Datensätze: '}${oReturn.cOut}</br>` + `Status: ${oReturn.cStatus}`);
  akioma.messaging.documentInfo ('Mailing beendet', oReturn.cStatus, 'Datei öffnen', oReturn.cOut);

  return false;
}

export function QuoteNewG_OK(self) {
  newDlg_OK (self.container.getObject('quotenewv'));
}

export function wRecalcOffer_OK(self) {
  const oViewer = self.container.getObject('vRecalcOfr');
  const glReplaceVAT = oViewer.getField('glReplaceVAT');
  const replaceVAT = glReplaceVAT.akEvent.currentValue;

  const gcTax = oViewer.getField('gcTax');
  const recalculateTax = gcTax.akEvent.currentValue.id == 'R';

  const executeRecalcOffer = () => {
    let cStructHdl = '';

    const oSDO = self.container.caller.container.getLink('PRIMARYSDO:TARGET');
    if (isValidObj(oSDO) == false) {
      akioma.log.error(`cannot get Primary-SDO Target for: ${self.container.caller.name}`);
      return '';
    }

    const lcOfferHdl = oSDO.getValue('selfhdl');

    const oTree = self.container.controller.parent.getDescendant('treegrid');
    if (isValidObj(oTree) == true)
      cStructHdl = oTree.getValue('selfhdl');

    self.container.controller.setWaitState('wait');

    const oReturn = app.controller.callServerMethod('mod/offer/recalcOffer.p', [
      { type: 'iCHAR', value: lcOfferHdl },
      { type: 'iCHAR', value: cStructHdl },
      { type: 'iTT', value: oViewer.getAllValues() },
      { type: 'iCHAR', value: '' },
      { type: 'oCHAR', name: 'cResult' },
      { type: 'oCHAR', name: 'cStatus' }
    ]);

    // refresh visible nodes descriptions
    if (oTree) {
      oTree.refreshAllVisibileNodes();
      akioma.swat.RefreshEmitter.refreshData('eDummyItem', { lastRowState: 'update' });
    }

    self.container.controller.setWaitState('');

    if (oReturn.cStatus != '') {
      try {
        akioma.showServerMessage(oReturn.cStatus, '');
      } catch (err) {
        akioma.log.error(`error displaying error: ${err.description}  ${oReturn.cStatus}`);
      }
    } else if (replaceVAT) {
      const replaceVatSuccessTitle = akioma.tran('vRecalcOfr.replaceVat.success.title', { defaultValue: 'Berechnung erfolgreich abgeschlossen' });
      const recalcMessage = `vRecalcOfr.replaceVat.success.message${oReturn.cResult > 1 ? 'n' : '1'}`;
      const replaceVatSuccessMessage = akioma.tran(recalcMessage, {
        defaultValue: `Es wurde die MwSt.-Rate von insgesamt __recalculatedItems__ Position${oReturn.cResult > 1 ? 'en' : ''} geändert`,
        recalculatedItems: oReturn.cResult
      });

      akioma.message({ type: 'success', text: replaceVatSuccessMessage, title: replaceVatSuccessTitle });
      self.controller.getAncestor('window').close();
    } else if (recalculateTax)
      self.controller.getAncestor('window').close();

    return true;
  };

  // Mechanism for ensuring that the user has to confirm all dialogs before recalculation
  // And to stop showing confirmations if he decides to press Cancel
  const confirmationPopups = new akioma.AkQueue();

  /**
   * Checks if there are any remaining confirmation dialogs. Display next dialog, otherwise executes recalculation
   */
  const tryExecuteRecalculate = () => {
    if (confirmationPopups.isEmpty())
      return executeRecalcOffer();

    const displayConfirmation = confirmationPopups.pop();
    displayConfirmation();
    return false;
  };

  /**
   * Display confirmation dialog for Rabatt recalculation
   */
  const recalculateTaxConfirmation = () => {
    const messageTitle = akioma.tran('vRecalcOfr.recalculateTax.confirmation.title', { defaultValue: 'Bestätigung' });
    const messageText = akioma.tran('vRecalcOfr.recalculateTax.confirmation.message', { defaultValue: 'Die MwSt.-Sätze aller ERP-Artikel werden auf den MwSt.-Satz des Angebotskopfs gesetzt. Sind Sie sich sicher?' });

    akioma.message({
      type: 'confirm',
      title: messageTitle,
      text: messageText,
      callback: function(buttonOk) {
        if (buttonOk)
          tryExecuteRecalculate();
      }
    });
  };

  /**
   * Display confirmation dialog for Replace VAT functionality
   */
  const replaceVATConfirmation = () => {
    const gisourcevat = oViewer.getField('gisourcevat');
    const gstargetvat = oViewer.getField('gstargetvat');
    const sourceVat = gisourcevat.akEvent.currentValue;
    const targetVat = gstargetvat.getValue();

    if (isNull(sourceVat) || sourceVat === '' || isNull(targetVat) || targetVat === '') {
      const sourceTargetEmptyMessage = akioma.tran('vRecalcOfr.replaceVat.errorReplaceVatEmpty', { defaultValue: 'MwSt.-Ausgangs- und Zielwert dürfen nicht leer sein' });
      akioma.message({ text: sourceTargetEmptyMessage });
      return '';
    }

    const priceCalculation = oViewer.getField('gcPrices').akEvent.currentValue.id;
    if (priceCalculation === '-') {
      const messageTitle = akioma.tran('vRecalcOfr.replaceVat.noPriceCalculationConfirmation.title', { defaultValue: 'Bestätigung' });
      const messageText = akioma.tran('vRecalcOfr.replaceVat.noPriceCalculationConfirmation.message', { defaultValue: 'Sind Sie sicher, dass Sie die MwSt.-Sätze ohne eine Neuberechnung der Preise durchführen möchten' });

      akioma.message({
        type: 'confirm',
        title: messageTitle,
        text: messageText,
        buttonText: 'Ok',
        callback: function(buttonOk) {
          if (buttonOk)
            tryExecuteRecalculate();
        }
      });
    }
  };


  if (recalculateTax)
    confirmationPopups.push(recalculateTaxConfirmation);

  if (replaceVAT)
    confirmationPopups.push(replaceVATConfirmation);

  return tryExecuteRecalculate();
}

export function contactg_OK() {
  return true;
}

export function ImpClipboardG_OK(self) {
  const oTree = self.container.caller.container.getObject('itTree');
  let cClip = '';
  const oViewer = self.parent.getObject('ImpclipboardV');
  const cContent = oViewer.getValue('gcText');
  const cHdl = oTree.getValue('selfhdl');

  if (!cContent) {
    try {
      cClip = window.clipboardData.getData('text');
    } catch (err) {
      cClip = '';
      akioma.log.error('Cannot get clipbord data:', err);
    }
    if (!cClip) {
      akioma.log.error('Aufgrund der Browser-Sicherheitseinstellungen konnte der Inhalt der Zwischenablage nicht ermittelt werden!<br>Verwenden Sie stattdessen Ctrl-V im Eingabefeld.');
      return false;
    }
  } else
    cClip = cContent;

  const oReturn = app.controller.callServerMethod('stubs/impstruct.p', [
    { type: 'iCHAR', value: cHdl },
    { type: 'iCHAR', value: cClip },
    { type: 'iTT', value: oViewer.getAllValues() },
    { type: 'iCHAR', value: '' },
    { type: 'oCHAR', name: 'cOut' },
    { type: 'oCHAR', name: 'cReturn' }
  ]);

  if (!oReturn.errorStatus) {
    const akTree = oTree.controller;

    let bChild = (oViewer.getValue('corelation') == 3);
    if (akTree.cGridRootNodeId == cHdl)
      bChild = true;

    if (bChild) // refresh tree in ui
      akTree.refreshNodes(cHdl, 'child');
    else
      akTree.refreshNodes(cHdl, 'sibling');

    akioma.notification({
      type: 'info',
      text: `${oReturn.cReturn}<br>` + '',
      lifetime: 10000
    });
  }

  return true;
}

export function offernewg_OK() {
  return;
}

export function dummyPosFromItemG_OK(self) {
  const lSkip = true;

  let oNavTarget = getActionContainer(self, lSkip);
  oNavTarget = oNavTarget.getLink('PRIMARYSDO:TARGET');
  if (isValidObj(oNavTarget) == false) {
    akioma.log.error(`cannot get Primary-SDO Target for: ${oNavTarget.name} (${oNavTarget.type})`);
    return '';
  }

  const oViewer = self.parent.getObject('dummyPosFromItemV');
  const cSelfHdl = oNavTarget.getValue('selfhdl');
  const cRefHdl = oNavTarget.getValue('refhdl');
  const oReturn = app.controller.callServerMethod('stubs/dummyfromitem.p', [
    { type: 'iCHAR', value: cSelfHdl },
    { type: 'iCHAR', value: cRefHdl },
    { type: 'iCHAR', value: '' },
    { type: 'iTT', value: oViewer.getAllValues() },
    { type: 'oCHAR', name: 'creturn' },
    { type: 'oCHAR', name: 'cStatus' }
  ]);

  akioma.messaging.info (`${'Fertig. </br>' + 'Ergebnis: '}${oReturn.cStatus}`);

  oNavTarget.controller.reloadData();
  const oTree = self.container.caller.container.getObject('itTree');
  oTree.controller.refreshAllVisibileNodes();

  self.container.controller.close();
}

export function TreeFlagsDialog_OK(self) {
  const callerContainer = self.container.caller.container;
  const structTree = callerContainer.getObject('itTree');
  const treeFlagsForm = self.container.getObject('TreeFlagsForm');
  const SetStructFlagParameter = {
    plcParameter: {
      StructHdl: structTree.getValue('selfhdl'),
      Flag: treeFlagsForm.getValue('flag')
    }
  };

  akioma.invokeServerTask({
    name: structTree.controller.getOperationsEntity(),
    methodName: 'SetStructFlag',
    paramObj: SetStructFlagParameter,
    showWaitCursor: true,
    uiContext: callerContainer
  });

  return true;
}

export function TreeFlagsG_OK(self) {
  const oTree = self.container.caller.container.getObject('itTree');
  const cStructHdl = oTree.getValue('selfhdl');
  const oViewer = self.container.getObject('TreeFlagsV');
  let oValues;
  let temp = '';
  let cValue = '';

  try {
    if (oViewer)
      oValues = oViewer.getAllValues(null, 'array');

    for (const i in oValues) {
      temp = oValues[i].split('#');
      if (!(temp[2] == 'no' || temp[2] == 'false')) {
        if (cValue)
          cValue += ' ';
        cValue += temp[0];
      }
    }

    cValue = akioma.stringReplace(cValue, 'AttributeFlags_input_', '');

    app.controller.callServerMethod('stubs/setTreeFlags.p', [
      {
        type: 'iCHAR',
        value: cStructHdl
      }, {
        type: 'iCHAR',
        value: cValue
      }, {
        type: 'oCHAR',
        name: 'cStatus'
      }
    ]);
    oTree.controller.refreshDesc(cStructHdl);
  } catch (err) {
    akioma.log.error([ 'error: ', err ]);
  }

  return true;
}

export function AttributeG_OK(self) {
  const cStructHdl = getCallerValue2(self, 'selfhdl');
  const oViewer = self.container.getObject('vAttribute');
  let cGrid = '';
  if (oViewer)
    cGrid = oViewer.getAllValues();

  if (isValidHdl(cStructHdl) == false) {
    akioma.log.error(`cannot get Parent Record: ${self.container.caller.name}`);
    return '';
  }

  app.controller.callServerMethod('conf/setAttributes2.p', [
    { type: 'iCHAR', value: cStructHdl },
    { type: 'iTT', value: cGrid },
    { type: 'oCHAR', name: 'cStatus' }
  ]);

  self.container.controller.close();
}

export function dlgDragDrop_OK(oSelf) {
  const oForm = oSelf.parent,
    cTargetId = oSelf.container.controller.opt.targetId,
    oTarget = $.getObjectByName({ id: cTargetId });
  akioma.treeDragAndDrop.executeDrop.apply(oTarget, [oForm]);
}
