export function dlgDragDrop_Cancel(oSelf) {
  akioma.treeDragAndDrop.onDialogCancel(oSelf);
}

/**
 * Print dialog (chooseFormV) locale form field (gcLocaleHdl) validate event handler.
 * Enables or disables legacy localization fields depending if the new locale field is set.
 * @param {*} self The locale form field dnObject
 */
export function dlgPrint_LocaleChoose(self) {
  const form = self.controller.form;
  const value = self.getValue();

  if (value === '' || isNull(value)) {
    form.enableFormField('gclanghdl');
    form.enableFormField('gcnumformat');
    form.enableFormField('gcdateformat');
  } else {
    form.disableFormField('gclanghdl');
    form.disableFormField('gcnumformat');
    form.disableFormField('gcdateformat');

    form.setValue('gclanghdl', '');
    form.setValue('gcnumformat', 'AUTO');
    form.setValue('gcdateformat', 'AUTO');
  }
}

export function dlgPrint_FormChoose(oSelf) {
  const oForm = oSelf.parent;
  const lcFormHdl = oSelf.getValue('selfkey');
  const lcOptions = '';
  const oReturn = app.controller.callServerMethod('stubs/getFormDefinition.p', [
    { type: 'iCHAR', value: lcFormHdl },
    { type: 'iCHAR', value: lcOptions },
    { type: 'oCHAR', name: 'cLangHdl' },
    { type: 'oCHAR', name: 'cLangList' },
    { type: 'oCHAR', name: 'cDocFile' },
    { type: 'oCHAR', name: 'lSaveLineNo' },
    { type: 'oCHAR', name: 'cNotes' },
    { type: 'oCHAR', name: 'cStatus' }
  ]);

  if (oReturn.cStatus) {
    akioma.notification({
      type: 'error',
      text: oReturn.cStatus,
      lifetime: -1
    });
  }

  if (oReturn.cNotes) {
    akioma.notification({
      type: 'info',
      text: oReturn.cNotes,
      lifetime: -1
    });
  }

  oForm.setValue('gcDocFile', oReturn.cDocFile);
  oForm.setValue('gcLangList', oReturn.cLangList);

  if (app.sessionData.customization == 'LN') {
    const iApprovalState = getCallerValue2(oSelf, 'approvalstate');
    if (iApprovalState != 10) {
      akioma.notification({
        type: 'info',
        text: akioma.tran('msg.ofrNotApproved', { defaultValue: 'Achtung: Angebot ist nicht freigegeben!<br>Freigabeprozess starten! ' }),
        expire: 5000
      });
    }
  }

}

export function dlgPrintClick(oSelf) {
  if (oSelf.type == 'toolbar')
    oSelf = oSelf.getLink('TOOLBAR:TARGET');

  const oWin = oSelf.container;
  let lcOfferHdl = getCallerValue2(oSelf, 'selfhdl');
  let lcOptions = '';
  let cStructHdl = '';
  let oTree = null;

  if (!isValidHdl(lcOfferHdl)) {
    akioma.showMessage(`Datensatz konnte nicht ermittelt werden für: ${oSelf.caller.container.name}`, 'alert-error');
    return;
  }

  try {
    oTree = oSelf.container.caller.container.getObject('itTree');
    if (oTree) {
      cStructHdl = oTree.getValue('selfhdl');
      if (isValidHdl(cStructHdl))
        lcOfferHdl = `${lcOfferHdl},${cStructHdl}`;
    }
  } catch (err) {
    akioma.log.error(`error getting Tree-Node: ${err.message}`);
  }

  lcOptions = oSelf.container.name;

  const parametersList = [];
  oSelf.getAllValues().split('|').forEach(parameter => {
    const values = parameter.split('#');
    if (values[0].startsWith('gl')) {
      if (values[2] == '0' || values[2] == 'false')
        values[2] = false;
      if (values[2] == '1' || values[2] == 'true')
        values[2] = true;
    }
    parametersList[values[0].toLowerCase()] = values[2];
  });

  akioma.invokeServerTask({
    name: 'Akioma.Crm.Offer.Printing.MsWordBT',
    methodName: 'ExportOffer',
    paramObj: {
      plcParameter: {
        RecHdl: lcOfferHdl,
        ExportOptions: lcOptions,
        SelfKey: parametersList['selfkey'],
        Options: parametersList['gcoptions'],
        LocaleHdl: parametersList['gclocalehdl'],
        LangHdl: parametersList['gclanghdl'],
        NumFormat: parametersList['gcnumformat'],
        DateFormat: parametersList['gcdateformat'],
        DocFile: parametersList['gcdocfile'],
        SaveLineNo: (parametersList['glsavelineno'] ? 'yes' : 'no'),
        DocumentDescription: parametersList['gcdocumentdescription'],
        PrintScope: parametersList['gcprintscope'],
        IgnoreFieldList: parametersList['glignorefieldlist'],
        FieldLogging: parametersList['glfieldlogging'],
        LangList: parametersList['gclanglist'],
        InternalDesc: parametersList['gcinternaldesc'],
        OfrState: parametersList['gcofrstate']
      }
    },
    uiContext: oWin.controller.getDescendant('form').dynObject,
    showWaitCursor: true
  }).done(oResult => {
    const oReturn = oResult.plcParameter;

    akioma.messaging.documentInfo (akioma.tran('msg.docPrinted', { defaultValue: 'Dokument wurde gedruckt' }), oReturn.Result, akioma.tran('msg.open', { defaultValue: 'Öffnen' }), oReturn.Status);
    if (oReturn.Result && (parametersList['glfieldlogging'] === 'yes' || parametersList['glfieldlogging'] === 'true' || parametersList['glfieldlogging'] === true)) {
      let fieldListDocument = oReturn.Result;

      // Obtain fieldListDocument filepathname from the docx filepathname.
      fieldListDocument = `${fieldListDocument.substring(0, fieldListDocument.lastIndexOf('.'))}_fieldinfo.xml`;

      akioma.messaging.documentInfo (akioma.tran('msg.docFieldListPrinted', { defaultValue: 'Feldinfo-Datei wurde erstellt' }), fieldListDocument, akioma.tran('msg.open', { defaultValue: 'Öffnen' }), oReturn.Status);
    }
    refreshCaller(oSelf, 'TOP');
    oSelf.container.controller.close();
  });
}

export function dlgFindClick(oSelf) {
  const oForm = oSelf.parent;
  const oTree = oSelf.container.caller.container.getObject('itTree');
  const cTreeHdl = oTree.getValue('selfhdl');
  const cItem = oForm.getValue('gcitem');
  let cLastFound = '';
  if (oSelf.lastResult)
    cLastFound = oSelf.lastResult;

  const oReturn = app.controller.callServerMethod('stubs/treefind.p', [
    { type: 'iCHAR', value: cTreeHdl },
    { type: 'iCHAR', value: cItem },
    { type: 'iCHAR', value: oForm.getValue('gcgroup') },
    { type: 'iCHAR', value: oForm.getValue('gcpos') },
    { type: 'iCHAR', value: cLastFound },
    { type: 'iCHAR', value: '' },
    { type: 'oCHAR', name: 'cstatus' }
  ]);

  oSelf.lastResult = oReturn.cstatus;

  if (isValidHdl(oReturn.cstatus))
    oTree.controller.reposition2Hdl(oReturn.cstatus);
  else
    akioma.messaging.info(oReturn.cstatus);
}

export function textCopyModule(oToolbar) {
  const self = oToolbar.container.caller;
  const typeHdl = self.getValue('textType');

  const oNavTarget = self.getLink('TABLEIO:TARGET').getLink('DISPLAY:SOURCE');
  if (isValidObj(oNavTarget) == false) {
    akioma.log.error(`cannot get Display-Source for: ${self.name} (${self.type})`);
    return;
  }

  const refHdl = oNavTarget.getValue('selfhdl');
  const txtMod = oToolbar.getLink('TOOLBAR:TARGET').getValue('gctxtmod');

  const oReturn = app.controller.callServerMethod('stubs/copytextmod.p', [
    { type: 'iCHAR', value: refHdl },
    { type: 'iCHAR', value: typeHdl },
    { type: 'iCHAR', value: txtMod },
    { type: 'iCHAR', value: '' },
    { type: 'oCHAR', name: 'cstatus' }
  ]);

  akioma.notification({
    type: 'info',
    text: `Fertig. ${oReturn.cstatus}`,
    lifetime: 10000
  });

  oNavTarget.controller.reloadData();
  oToolbar.container.controller.close();
}

export function dlgCancelClick(oSelf) {
  try {
    oSelf.container.controller.close();
  } catch (err) {
    akioma.log.error([ 'error: in dialogCancel ', err ]);
  }
}
