import { calculateProdCosts } from './common';

export function setDiscount(self) {
  const form = self.parent;

  const discountValueString = self.controller.businessEntity.getFieldValue('misc1');
  if (discountValueString == '') return;

  const discountValue = Number(discountValueString);
  if (isNaN(discountValue))
    akioma.notification({ type: 'warning', text: `Attempted to assign invalid discount '${discountValueString}', please check configuration!` });
  else {
    form.setValue('vendordiscount', discountValue);
    const vendordiscount = form.getObject('vendordiscount');
    calculateProdCosts(vendordiscount, 'dummy');
  }
}
