import { handleRecordNotes } from '../global';
import { calculateAmount, calculateProdCosts, getMyValue, getPrice } from './common';

export * as createDialog from './createDialog';
export * as dummy from './dummy';
export * as sumposofrf from './sumposofrf';
export { calculateAmount, calculateProdCosts, getPrice };

export function exportItemTextToWord(self, cMode) {
  const businessEntityName = 'Akioma.Crm.MasterData.Item.ErpItemEntity';
  const templateFile = '90';
  const gridName = 'article_crmallb';
  let cSelectedRows = '';
  const lLetterMode = true;

  if (!cMode)
    cMode = 'selection';

  const gridController = self.parent.getObject(gridName).controller;
  const filterArr = gridController.getFilterArray();
  let filterStr = '';

  if (cMode == 'selection')
    cSelectedRows = gridController.dynObject.getValue();

  else {
    for (let idx = 0; idx < filterArr.fields.length; idx++) {
      filterStr += `${(filterStr ? ';' : '')
        + filterArr.fields[idx]}|${filterArr.operators[idx]}|${filterArr.values[idx]}`;
    }
  }

  akioma.invokeServerTask({
    name: 'Akioma.Crm.Printing.ExportGridTask',
    methodName: 'ExportGrid',
    paramObj: {
      plcParameter: {
        BusinessEntity: businessEntityName,
        Template: templateFile,
        Filter: filterStr,
        SelfHdlList: cSelectedRows,
        LetterMode: lLetterMode
      }
    }
  })
    .always(ret => {
      ret = ret.plcParameter;

      if (ret.Error) {
        akioma.notification({
          type: 'error',
          text: ret.Error,
          lifetime: -1
        });
      } else {
        akioma.messaging.documentInfo(
          akioma.tran('msg.docPrinted', { defaultValue: 'Dokument wurde gedruckt' }),
          ret.Result,
          akioma.tran('msg.open', { defaultValue: 'Öffnen' }),
          'OK'
        );
      }
    });
}

export function exportStructToExcel(oSelf) {
  let cHdl = '';
  try {
    cHdl = getSDOValue(oSelf, 'selfhdl');
  } catch (e) {
    akioma.log.error(e);
  }
  if (!isValidHdl(cHdl)) {
    akioma.messaging.error('Es konnten keine Daten ermittelt werden.');
    return;
  }

  akioma.invokeServerTask({
    name: 'Akioma.Swat.Struct.Printing.MsExcelBT',
    methodName: 'ExportStruct',
    paramObj: {
      plcParameter: {
        BusinessEntityName: 'Akioma.Crm.Offer.OfferStructEntity',
        OwnerHdl: cHdl,
        SelfKey: '37'
      }
    }
  })
    .done(oReturn => {
      if (oReturn.plcParameter.Result) {
        akioma.messaging.documentInfo(
          akioma.tran('msg.docPrinted', { defaultValue: 'Dokument wurde gedruckt' }),
          oReturn.plcParameter.Result,
          akioma.tran('msg.open', { defaultValue: 'Öffnen' }),
          'OK');
      }
    });
}

function disablePriceDiscountFields(form) {
  const fields = [
    'pricegross',
    'pricegrossfc',
    'amountgross',
    'amountgrossfc',
    'discountpercentage',
    'discountamount',
    'discountamountfc',
    'discountorigin',
    'price',
    'pricefc',
    'amount',
    'amountfc'
  ];
  for (const field of fields)
    form.controller.disableFormField(field);
}

export function articlePosOnInitialize(oSelf) {
  // fetching offer currency symbol
  const cCurrencySymbol = oSelf.container.getLink('PRIMARYSDO:TARGET').getValue('currencysc');

  oSelf.controller.applyNumericFormats(cCurrencySymbol);

  if (akioma.getSessionProperty('customization') === 'CBRE') {
    const attributeFlags = oSelf.container.getLink('PRIMARYSDO:TARGET').getValue('attributeflags');
    if (attributeFlags.toLowerCase() === 'v')
      disablePriceDiscountFields(oSelf);
  }
}

export function saveColtHeaderData() {}

export function delSalesConfPos(oSelf) {
  const oSDO = oSelf.container.getLink('PRIMARYSDO:TARGET');
  if (isValidObj(oSDO) == false) {
    akioma.log.error(`cannot get Primary-SDO Target for: ${oSelf.container.name}`);
    return '';
  }

  const oTree = oSelf.container.getObject('itTree');
  const cStructHdl = isValidObj(oTree) ? oTree.getValue('selfhdl') : '';
  const lcOfferHdl = oSDO.getValue('selfhdl');
  const oReturn = app.controller.callServerMethod('mod/offer/delSalesConfPos.p', [
    { type: 'iCHAR', value: lcOfferHdl },
    { type: 'iCHAR', value: cStructHdl },
    { type: 'oCHAR', name: 'cStatus' }
  ]);

  if (oReturn.cStatus)
    akioma.showServerMessage(oReturn.cStatus, '');
  else
    oSDO.controller.reloadData();
}

/* convCurrency */
export function convCurrency(oSelf, cOptions, cOther) {
  const oForm = akioma.getForm(oSelf.controller).dynObject;
  const oAmount = oSelf;
  const oAmountTo = oForm.getField(cOther);
  const cCurrency = getMyValue(oSelf, 'currencyhdl');

  if (cOptions === 'base') {
    const oReturn = akioma.callServerMethod('stubs/convtocurrency.p', [
      { type: 'iCHAR', value: cCurrency },
      { type: 'iDEC', value: 0 },
      { type: 'iDEC', value: 0 },
      { type: 'iDEC', value: oAmount.getValue() },
      { type: 'oDEC', name: 'eConverted' }
    ]);
    oAmountTo.setValue('', oReturn.eConverted);

  }
  if (cOptions === 'foreign') {
    const oReturn = akioma.callServerMethod('stubs/convfromcurrency.p', [
      { type: 'iCHAR', value: cCurrency },
      { type: 'iDEC', value: 0 },
      { type: 'iDEC', value: 0 },
      { type: 'iDEC', value: oAmount.getValue() },
      { type: 'oDEC', name: 'eConverted' }
    ]);
    oAmountTo.setValue('', oReturn.eConverted);
  }
}
/* end of convCurrency*/

export function sendAsMail_Init(self) {
  const oForm = self.getObject('sendOfferV');
  const lcOfferHdl = getCallerValue2(self, 'selfhdl');
  const oReturn = app.controller.callServerMethod('mod/offer/getOfferMailInfo.p', [
    { type: 'iCHAR', value: lcOfferHdl },
    { type: 'iCHAR', value: '' },
    { type: 'oCHAR', name: 'cMailAdr' },
    { type: 'oCHAR', name: 'cMailBody' },
    { type: 'oCHAR', name: 'cSubject' },
    { type: 'oCHAR', name: 'cStatus' }
  ]);

  oForm.setValue('gcsubject', oReturn.cSubject);
  oForm.setValue('gcmailadr', oReturn.cMailAdr);

  const oText = self.getObject('itText');
  if (!oText) {
    akioma.showServerMessage('cannot dtermine Text!');
    return;
  }
  oText.controller.dhx.setItemValue('txthtmlx', oReturn.cMailBody);
}

export function sendAsMail(oSelf) {
  const oForm = oSelf.getLink('TOOLBAR:TARGET');
  const oDocSDO = oSelf.container.getObject('dextdoc');
  const lcOfferHdl = getCallerValue2(oSelf, 'selfhdl');
  let cText = '';
  let lcOptions = '';

  if (!isValidHdl(lcOfferHdl)) {
    akioma.showMessage(`Datensatz konnte nicht ermittelt werden für: ${oSelf.caller.container.name}`, 'alert-error');
    return;
  }

  const oText = oSelf.container.getObject('itText');
  if (!oText) {
    akioma.showServerMessage('cannot dtermine Text!');
    return;
  }

  // get html and convert text
  const cHTML = oText.controller.dhx.getItemValue('txthtmlx');
  try {
    cText = $(`<div>${cHTML}</div>`).text();
  } catch (e) {
    akioma.log.error(`Error converting HTML to text: ${e.message}`);
  }

  lcOptions = oDocSDO.getValue('selfhdl');

  const oReturn = app.controller.callServerMethod('mod/offer/sendOfferAsMail.p', [
    { type: 'iCHAR', value: lcOfferHdl },
    { type: 'iCHAR', value: lcOptions },
    { type: 'iTT', value: oForm.getAllValues() },
    { type: 'iLONG', value: cHTML },
    { type: 'iLONG', value: cText },
    { type: 'oCHAR', name: 'creturn' },
    { type: 'oCHAR', name: 'cstatus' }
  ]);

  akioma.messaging.documentInfo ('Dokument wurde verschickt:', oReturn.creturn, 'Öffnen', oReturn.cstatus);
}

export function addAltItem(self) {
  const oGrid = self.container.getObject('articlecrm1b');
  const oActivity = self.container.caller.container.getLink('PRIMARYSDO:TARGET');
  const cActivity = oActivity.getValue('selfhdl');

  const oSDO = oGrid.getLink('DATA:SOURCE');
  if (isValidObj(oSDO) == false) {
    akioma.log.error(`cannot get Data-Source  for: ${oGrid.name}`);
    return '';
  }

  const lcHdl = oSDO.getValue('selfhdl');
  const oReturn = app.controller.callServerMethod('mod/conf/itemAddAlternative.p', [
    { type: 'iCHAR', value: cActivity },
    { type: 'iCHAR', value: lcHdl },
    { type: 'oCHAR', name: 'cResult' }
  ]);

  akioma.messaging.info(`${'Fertig' + '</br>'}${oReturn.cResult}`);

  oActivity.controller.reloadData();
}

export function nextTran(self) {
  const oTree = self.container.getObject('itTree');
  const oSDO = self.container.getObject('dTranList');
  const cTranEntry = oSDO.getValue('parenthdl');
  if (isValidHdl(cTranEntry))
    oTree.controller.reposition2Hdl(cTranEntry);
}

export function reApplyOfferAttributes(self) {
  let cHdl = '';
  let cDesc = '';
  try {
    cHdl = getSDOValue(self, 'selfhdl');
    cDesc = getSDOValue(self, 'selfdesc');
  } catch (e) {
    akioma.log.error([ 'error: ', e ]);
  }

  if (isValidHdl(cHdl) == false) {
    akioma.messaging
      .error('Es konnten keine Daten ermittelt werden.');
    return;
  }

  dhtmlx.confirm({
    type: 'confirm',
    text: `${cDesc}<br> Varianten neu ermitteln?`,
    callback: function(result) {
      if (result == true) {
        const oReturn = app.controller.callServerMethod(
          'mod/offer/reApplyAttributes.p', [
            {
              type: 'iCHAR',
              value: cHdl
            }, {
              type: 'oCHAR',
              name: 'creturn'
            }
          ]);
        akioma.messaging.info(`Neuermittlung beendet. </br>${oReturn.creturn}`);
      }
    }
  });
}

export function sumBuild(self) {
  let cHdl = '';
  try {
    cHdl = getSDOValue(self, 'selfhdl');
  } catch (e) {
    akioma.log.error([ 'error: ', e ]);
  }

  if (isValidHdl(cHdl) == false) {
    akioma.messaging.error('Es konnten keine Angebots-Daten ermittelt werden.');
    return;
  }

  akioma.callServerMethod('mod/offer/getOfrRecalcOptions.p', [
    { type: 'iCHAR', value: cHdl },
    { type: 'iCHAR', value: null },
    { type: 'oCHAR', name: 'cOfferDesc' },
    { type: 'oCHAR', name: 'cStructDesc' },
    { type: 'oCHAR', name: 'eFactor' }
  ]);
}

export function showProdCostWindow(self) {
  let cHdl = '';
  try {
    cHdl = getSDOValue(self, 'selfhdl');
  } catch (e) {
    akioma.log.error([ 'error: ', e ]);
  }

  if (isValidHdl(cHdl) == false) {
    akioma.messaging.error('Es konnten keine Angebots-Daten ermittelt werden.');
    return;
  }

  app.controller.launchContainer({
    containerName: 'offersumw',
    self: this,
    para: `selfHdl=${cHdl}`,
    repositionTo: cHdl
  });
}

export function recalcOffer_Init(self) {
  const oViewer = self.container.getObject('vRecalcOfr');
  let cStructHdl = '';

  const oSDO = self.container.caller.container.getLink('PRIMARYSDO:TARGET');
  if (isValidObj(oSDO) == false) {
    akioma.log.error(`cannot get Primary-SDO Target for: ${self.container.caller.name}`);
    return '';
  }

  const lcOfferHdl = oSDO.getValue('selfhdl');

  const oTree = self.container.caller.container.getObject('itTree');
  if (isValidObj(oTree) == true)
    cStructHdl = oTree.getValue('selfhdl');


  const oReturn = app.controller.callServerMethod('mod/offer/getOfrRecalcOptions.p', [
    { type: 'iCHAR', value: lcOfferHdl },
    { type: 'iCHAR', value: cStructHdl },
    { type: 'oCHAR', name: 'cOfferDesc' },
    { type: 'oCHAR', name: 'cStructDesc' },
    { type: 'oCHAR', name: 'eFactor' }
  ]);

  oViewer.setValue('gepricefactor', oReturn.eFactor);
  oViewer.setValue('gcoldfactor', oReturn.eFactor);
  oViewer.setValue('selfno', oReturn.cOfferDesc);
  oViewer.setValue('selfdesc', oReturn.cStructDesc);
}

export function toggleReplaceVAT(self) {
  const formController = self.parent.controller;

  if (self.akEvent.currentValue) {
    formController.enableFormField('giSourceVAT');
    formController.enableFormField('gsTargetVAT');
  } else {
    formController.disableFormField('giSourceVAT');
    formController.disableFormField('gsTargetVAT');
  }
}

export function printDlg_Init(self) {
  const oViewer = self.container.getObject('ChooseFormv');
  let oField = oViewer.getField('selfkey');

  const oSDO = self.container.caller.parent.getLink('PRIMARYSDO:TARGET');
  if (isValidObj(oSDO) == false) {
    akioma.log.error(`cannot get Primary-SDO Target for: ${self.container.caller.name}`);
    return '';
  }

  const lcOfferHdl = oSDO.getValue('selfhdl');
  const oReturn = app.controller.callServerMethod('stubs/getPrintOptions.p', [
    { type: 'iCHAR', value: lcOfferHdl },
    { type: 'oCHAR', name: 'cLangHdl' },
    { type: 'oCHAR', name: 'cFormHdl' }
  ]);

  oField = oViewer.getField('gclanghdl').controller;
  oField.dynSelectControl.positionToRecord(oReturn.cLangHdl);
}

export function doc2erp_Init(self) {
  const oViewer = self.container.getObject('doc2erpv');
  const oCust = oViewer.getField('customerhdl');
  const oOfr = oViewer.getField('sourcehdl');

  const oSDO = self.container.caller.parent.getLink('PRIMARYSDO:TARGET');
  if (isValidObj(oSDO) == false) {
    akioma.log.error(`cannot get Primary-SDO Target for: ${self.container.caller.name}`);
    return '';
  }

  const lcOfferHdl = oSDO.getValue('selfhdl');
  const oReturn = app.controller.callServerMethod('stubs/getOfferDetails.p', [
    { type: 'iCHAR', value: lcOfferHdl },
    { type: 'oCHAR', name: 'cCustHdl' },
    { type: 'oCHAR', name: 'cCustKey' },
    { type: 'oCHAR', name: 'cContactKey' },
    { type: 'oCHAR', name: 'cContactHdl' },
    { type: 'oCHAR', name: 'cLangHdl' },
    { type: 'oCHAR', name: 'cFormHdl' }
  ]);

  oCust.controller.dynSelectControl.positionToRecord(oReturn.cCustHdl);

  oOfr.controller.dynSelectControl.positionToRecord(lcOfferHdl);
  oOfr.controller.dynSelectControl.positionToRecord(lcOfferHdl);

  oViewer.setValue('gclanghdl', oReturn.cLangHdl);
}

export function sendDocToERP(self) {
  if (self.type == 'toolbar')
    self = self.getLink('TOOLBAR:TARGET');

  const cSelfHdl = getMyValue(self.container.caller, 'selfhdl');

  const oReturn = app.controller.callServerMethod('stubs/sendDocumentToERP.p', [
    { type: 'iCHAR', value: cSelfHdl },
    { type: 'iCHAR', value: '' },
    { type: 'iTT', value: self.getAllValues() },
    { type: 'oCHAR', name: 'cERPNumber' },
    { type: 'oCHAR', name: 'creturn' },
    { type: 'oCHAR', name: 'cStatus' }
  ]);

  akioma.messaging.info (`${'Übertragung beendet. </br>' + 'ERP-Beleg: '}${oReturn.cERPNumber}</br>` + `Status: ${oReturn.cStatus}`);

  const oCaller = self.container.caller;
  if (isValidObj(oCaller) == false) {
    akioma.log.error(`cannot get caller for: ${self.name}`);
    return '';
  }

  const oNavTarget = oCaller.container.getLink('PRIMARYSDO:TARGET');
  if (isValidObj(oNavTarget) == false) {
    akioma.log.error(`cannot get Primary-SDO Target for: ${oCaller.name} (${oCaller.type})`);
    return '';
  }

  refreshCaller(self, 'TOP');

  self.container.controller.close();
}

export function newDlg_leave(self) {
  let lVisible = false;

  if (self.name == 'gcmode') {
    lVisible = (self.getValue() != 'C');
    self.parent.controller.setVisible('copyoptions', lVisible);
  } else if (self.name == 'gcview') {
    lVisible = (self.getValue() == 'X');
    self.parent.controller.setVisible('expert', lVisible);
  } else if (self.name == 'customerhdl') {
    const cCustHdl = self.getValue();
    const oReturn = app.controller.callServerMethod('stubs/getOfferInitValues.p', [
      { type: 'iCHAR', value: cCustHdl },
      { type: 'iCHAR', value: '' },
      { type: 'oCHAR', name: 'cPriceGroup' },
      { type: 'oCHAR', name: 'cCurrency' },
      { type: 'oCHAR', name: 'cCountry' },
      { type: 'oCHAR', name: 'cSalesRep' },
      { type: 'oCHAR', name: 'cStatus' }
    ]);
    self.parent.setValue('pricegroup', oReturn.cPriceGroup);
    self.parent.setValue('currencyhdl', oReturn.cCurrency);
    self.parent.setValue('gcdestcountry', oReturn.cCountry);
    self.parent.setValue('salesrephdl', oReturn.cSalesRep);
  }
}

export function importItems(self) {
  const oTree = self.container.caller.container.caller.container.getObject('itTree');
  const oGrid = self.container.caller.controller;
  const oFilter = oGrid.getFilterArray();
  const oViewer = self.parent.getObject('OfferImportItemsV');
  const oController = oTree.controller;

  const cHdl = oTree.getValue('selfhdl');
  const cMode = oViewer.getValue('coresultlist');
  let cCorelation = oViewer.getValue('corelation');

  // if sibling and pos.own.ofr then add as child
  if (cCorelation === '1' && oController.typeKey === 'pos.own.ofr')
    cCorelation = '3';

  let cSelectedRows = '';
  let cFilter = '';

  if (cMode == 'selection')
    cSelectedRows = oGrid.dynObject.getValue();
  else {
    oGrid.getFilterArray().fields.forEach((cField, iIdx) => {
      cFilter += `${(cFilter ? ';' : '') + cField}|${oFilter.operators[iIdx]}|${oFilter.values[iIdx]}`;
    });
  }

  akioma.invokeServerTask({
    name: 'Akioma.Crm.Offer.OfferLines.ImportOfferLinesBT',
    methodName: 'ImportItems',
    paramObj: {
      plcParameter: {
        ParentHdl: cHdl,
        StructRelationType: cCorelation,
        Filter: cFilter,
        SelfHdlList: cSelectedRows
      }
    }
  }).always(oReturn => {
    oReturn = oReturn.plcParameter;

    if (oReturn.Error) {
      akioma.notification({
        type: 'error',
        text: oReturn.Error,
        lifetime: -1
      });
    } else {
      akioma.notification({
        type: 'info',
        text: `${'Fertig.' + '<br>'}${oReturn.Result}<br>` + '',
        lifetime: 10000
      });

      if (cCorelation === '3')
        oController.refreshNodes(cHdl, 'child');
      else
        oController.refreshNodes(cHdl, 'sibling');

    }
  });
}

export function newDlg_Init(self) {
  const oViewer = self.container.getObject('offernewv');
  let oField;
  let cContact;
  let cContactHdl;
  let cCustomer;
  let oSDO;

  if (self.container.caller && self.container.caller.getLink) {
    oSDO = self.container.caller.getLink('PRIMARYSDO:TARGET');
    if (!isValidObj(oSDO))
      oSDO = self.container.caller.parent.getLink('PRIMARYSDO:TARGET');
  }

  let lcOfferHdl;
  if (isValidObj(oSDO) == false) {
    if (self.controller.userData) {
      cCustomer = self.controller.userData.customer;
      cContact = self.controller.userData.contact;
      if (self.controller.userData.contactHdl && self.controller.userData.contactHdl != 'null')
        cContactHdl = self.controller.userData.contactHdl;
    } else // neither dataSource nor userData -> nothing to initialize
      return '';
  } else {
    lcOfferHdl = oSDO.getValue('selfhdl');
    if (oSDO.container.name.toLowerCase() === 'custsearchw')
      cContact = oSDO.container.getObject('contactd').getValue('contacthdl');
  }

  let cParamHdl;
  if (isValidHdl(cContactHdl))
    cParamHdl = cContactHdl;
  else if (cContact)
    cParamHdl = cContact;
  else if (cCustomer)
    cParamHdl = cCustomer;
  else
    cParamHdl = lcOfferHdl;


  const oReturn = app.controller.callServerMethod('stubs/getCustOfOffer2.p', [
    { type: 'iCHAR', value: cParamHdl },
    { type: 'oCHAR', name: 'cCustHdl' },
    { type: 'oCHAR', name: 'cCustKey' },
    { type: 'oCHAR', name: 'cCustDesc' },
    { type: 'oCHAR', name: 'cContactKey' },
    { type: 'oCHAR', name: 'cContactDesc' },
    { type: 'oCHAR', name: 'cContactHdl' },
    { type: 'oCHAR', name: 'cSalesRepHdl' },
    { type: 'oCHAR', name: 'cCurrencyHdl' },
    { type: 'oCHAR', name: 'cGroupHdl' },
    { type: 'oCHAR', name: 'cPriceGroup' }
  ]);

  if (cContact)
    oReturn.cContactKey = cContact;

  oField = oViewer.getField('customerhdl');

  oViewer.setValue('gccontacthdl', oReturn.cContactKey);
  if (oField && oField.controller.setValueExt)
    oField.controller.setValueExt(oReturn.cCustHdl, oReturn.cCustKey, oReturn.cCustDesc);

  const oFieldCustomer = oViewer.getField('customerhdl');
  if (oFieldCustomer)
    oFieldCustomer.controller.dynSelectControl.positionToRecord(oReturn.cCustHdl);

  const oFieldContact = oViewer.getField('gccontacthdl');
  if (oFieldContact)
    oFieldContact.controller.dynSelectControl.positionToRecord(oReturn.cContactHdl);

  const oFieldGroup = oViewer.getField('gcgrouphdl');
  if (oFieldGroup && isValidHdl(oReturn.cGroupHdl))
    oFieldGroup.controller.dynSelectControl.positionToRecord(oReturn.cGroupHdl);

  oViewer.setValue('salesrephdl', oReturn.cSalesRepHdl);
  oViewer.setValue('currencyhdl', oReturn.cCurrencyHdl);
  oViewer.setValue('pricegroup', oReturn.cPriceGroup);

  oViewer.setValue('gcmode', 'C');

  if (self.controller.userData) {
    oField = oViewer.getField('gcOptions');
    oField.setValue(`Opportunity=${self.controller.userData.opportunity}`);
  }

  if (oSDO && oSDO.name.toLowerCase() == 'offerd') {
    oField = oViewer.getField('sourcehdl');
    oField.controller.dynSelectControl.positionToRecord(oSDO.getValue('selfhdl'));
    oViewer.setValue('gcmode', 'CF');
    oViewer.setValue('selfdesc', `Kopie ${oSDO.getValue('selfdesc')}`);
    self.container.controller.setTitle('Angebot kopieren');
  }
}

export function newDlg_OK(self) {
  self.container.controller.getDescendant('form').submitRow();
  if (self.type == 'toolbar')
    self = self.getLink('TOOLBAR:TARGET');


  const cMode = self.getValue('gcmode');

  let createOfferParam = {};
  createOfferParam = {
    plcParameter:
   {
     Action: 'createOffer',
     Result: '',
     SelfNo: self.getValue('selfno'),
     SelfDesc: self.getValue('selfdesc'),
     CustomerHdl: self.getValue('customerhdl'),
     CurrencyHdl: self.getValue('currencyhdl'),
     ContactHdl: self.getValue('gccontacthdl'),
     GroupHdl: self.getValue('gcgrouphdl'),
     PriceGroup: self.getValue('pricegroup'),
     PriceFactor: self.getValue('pricefactor'),

     GCOptions: self.getValue('gcOptions'),
     SalesRepHdl: self.getValue('salesrephdl'),
     GCDestCountry: self.getValue('gcdestcountry'),

     GCMode: self.getValue('gcmode'),
     SourceHdl: self.getValue('sourcehdl'),
     StrRecalcPrices: self.getValue('strrecalcprices'),
     StrIncludeInSum: self.getValue('strincludeinsum'),
     CpOptQtyMode: self.getValue('cpoptqtymode'),
     StrMultiplyQty: self.getValue('strMultiplyQty')
   }
  };


  // call businessTaks method for creating new Offer
  if (self.controller.validate()) {
    akioma.invokeServerTask({
      name: 'Akioma.Crm.Offer.OfferBT',
      methodName: 'CreateOffer',
      paramObj: createOfferParam,
      uiContext: self.container.controller.getDescendant('form').dynObject,
      showWaitCursor: true
    }).done(function(oResult) {

      oResult = oResult.plcParameter;

      if (isValidHdl(oResult.Result) == false) {
        akioma.log.error(`Fehler in Angebotsanlage: ${oResult.Result}`);
        return;
      }


      const oPromiseLaunch = app.controller.launchContainer({
        self: this,
        extLink: oResult.Result,
        data: true
      });

      oPromiseLaunch.done(function(oNewContainer) {
        const cSourceHdl = self.getValue('sourcehdl');
        self.container.controller.close();

        if (cMode == 'CM' || cMode == 'CM2') {


          oNewContainer.posWindow('right');
          app.controller.launchContainer({
            extLink: cSourceHdl,
            position: 'fitToLeft',
            self: this,
            data: true
          });
        } else if (cMode == 'CMR') {

          oNewContainer.posWindow('left');
          app.controller.launchContainer({
            extLink: cSourceHdl,
            position: 'fitToRight',
            self: this,
            data: true
          });
        }
      });
    }).fail(() => {
      akioma.notification({ type: 'error', text: 'Could not create new offer.' });
    });
  }
}

export function itemLeave(self, cOptions) {
  const cItem = self.getValue();
  handleRecordNotes(cItem);
  /* put in its own function so it can be called directly through a button*/
  getPrice (self.parent, cOptions);
}

export function calculateAmountSalesItem(oSelf, cOptions) {
  calculateAmount (oSelf, cOptions);
  return;
}

export function calculateAmountDummy(oSelf, cOptions) {
  calculateAmount (oSelf, cOptions);
  return;
}

export function erpItemSelected(oSelf, cOptions) {
  const itemSelected = function(oSelf, cOptions) {
    const cItem = oSelf.getValue();
    handleRecordNotes(cItem);
    /* put in its own function so it can be called directly through a button*/
    getPrice (oSelf.parent, cOptions);
  };

  itemSelected(oSelf, cOptions);
}

export function discountOrigin_Change() {}

export function approveOffer(self) {
  const selectedOffer = self.parent.getLink('PRIMARYSDO:TARGET').controller.getSelectedRecord();

  if (!selectedOffer) {
    akioma.message({
      type: 'info',
      title: akioma.tran('messageBox.title.info', { defaultValue: 'Information' }),
      text: akioma.tran('messageBox.text.noOfferSelected', { defaultValue: 'Please select an offer!' })
    });
    return;
  }

  if (selectedOffer.approvalstate == 10) {
    akioma.message({
      type: 'info',
      title: akioma.tran('messageBox.title.info', { defaultValue: 'Information' }),
      text: akioma.tran('messageBox.text.alreadyApproved', { defaultValue: 'The selected offer is already approved!' })
    });
    return;
  }

  akioma.message({
    type: 'confirm',
    title: akioma.tran('messageBox.title.confirm', { defaultValue: 'Confirm' }),
    text: akioma.tran('messageBox.text.approvalConfirmation', { defaultValue: 'Are you sure you want to approve this offer?' }),
    buttons: {
      ok: akioma.tran('messageBox.button.yes', { defaultValue: 'Yes' }),
      cancel: akioma.tran('messageBox.button.no', { defaultValue: 'No' })
    },
    callback: function(lOk) {
      if (lOk) {

        app.controller.launchContainer({
          containerName: 'approveOfferDialog',
          self: self.parent,
          customData: { methodName: 'ApproveOffer' }
        });
      }
    }
  });
}

export function denyOffer(self) {
  const selectedOffer = self.parent.getLink('PRIMARYSDO:TARGET').controller.getSelectedRecord();

  if (!selectedOffer) {
    akioma.message({
      type: 'info',
      title: akioma.tran('messageBox.title.info', { defaultValue: 'Information' }),
      text: akioma.tran('messageBox.text.noOfferSelected', { defaultValue: 'Please select an offer!' })
    });
    return;
  }

  if (selectedOffer.approvalstate == 1) {
    akioma.message({
      type: 'info',
      title: akioma.tran('messageBox.title.info', { defaultValue: 'Information' }),
      text: akioma.tran('messageBox.text.alreadyDenied', { defaultValue: 'The selected offer is already denied!' })
    });
    return;
  }

  akioma.message({
    type: 'confirm',
    title: akioma.tran('messageBox.title.confirm', { defaultValue: 'Confirm' }),
    text: akioma.tran('messageBox.text.denialConfirmation', { defaultValue: 'Are you sure you want to deny the selected offer?' }),
    buttons: {
      ok: akioma.tran('messageBox.button.yes', { defaultValue: 'Yes' }),
      cancel: akioma.tran('messageBox.button.no', { defaultValue: 'No' })
    },
    callback: function(lOk) {
      if (lOk) {
        app.controller.launchContainer({
          containerName: 'approveOfferDialog',
          self: self.parent,
          customData: { methodName: 'DenyOffer' }
        });
      }
    }
  });
}

export function saveNotesOffer(self) {
  const caller = self.container.caller,
    selectedOffer = caller.getLink('PRIMARYSDO:TARGET').controller.getSelectedRecord(),
    selfHdl = selectedOffer.refhdl,
    relationhdl = selectedOffer.selfhdl,
    methodName = self.container.controller.customData.methodName,
    notes = self.container.getObject('notes').getValue();

  akioma.invokeServerTask({
    name: 'Akioma.Crm.Offer.OfferRoleRelationsEntity',
    methodName: methodName,
    showWaitCursor: true,
    uiContext: self.container,
    paramObj: {
      plcParameter: {
        OfferHdl: selfHdl,
        RelationHdl: relationhdl,
        Reason: notes
      }
    }
  }).done(() => {
    self.container.controller.close();
    const oDataSource = caller.getLink('PRIMARYSDO:TARGET').controller;
    oDataSource.reloadData();
  });
}

/**
 * Filter the dynselect for valid entries(starting with 'sum.ofr.')
 * @param {Object} self
 * @returns {void}
 */
export function filterForValidEntries(self) {
  self.businessEntity.getQuery().addUniqueCondition('selfkey', 'begins', 'sum.ofr.');
}
