export function onBeforeFetchSuperiorDesc(oSelf) {
  const oForm = akioma.getForm(oSelf.controller);
  const customerHdl = oForm.dataSource.getFieldValue('busrelhdl');
  if (isValidHdl(customerHdl))
    oSelf.controller.businessEntity.query.addUniqueCondition('busrelhdl', '=', customerHdl);
}

export function onBeforeFetchRepresentativeDesc(oSelf) {
  const oForm = akioma.getForm(oSelf.controller);
  const customerHdl = oForm.dataSource.getFieldValue('busrelhdl');
  if (isValidHdl(customerHdl))
    oSelf.controller.businessEntity.query.addUniqueCondition('busrelhdl', '=', customerHdl);
}
