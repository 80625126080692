export function openTranpack(self) {
  const oSDO = self.getLink('DATA:SOURCE');
  if (isValidObj(oSDO) == false) {
    akioma.log.error(`cannot get Primary-SDO Target for: ${self.container.name}`);
    return '';
  }

  const lcHdl = oSDO.getValue('selfhdl');
  try {
    const oReturn = app.controller.launchContainer({
      proc: 'tranPack2w.r',
      para: `SelfHdl=${lcHdl}&Page=0,1`,
      extLink: lcHdl,
      self: this,
      data: true
    });

    akioma.notification({
      type: 'info',
      text: `${oReturn.cStatus}<br>${oReturn.cResult}`,
      lifetime: 10000
    });
  } catch (err) {
    akioma.log.error(`error tranW: ${err.message}`);
  }
}

export function afterTranTextSave(self) {
  const oDataSource = self.controller.dataSource.dynObject;
  oDataSource.setValue('progressstatus', '3');
}

export function tranPack_Init(self) {
  const oViewer = self.container.getObject('vtranpack');

  const oSDO = self.container.caller.container.getLink('PRIMARYSDO:TARGET');
  if (isValidObj(oSDO) == false) {
    akioma.log.error(`cannot get Primary-SDO Target for: ${self.container.caller.name}`);
    return '';
  }

  oViewer.setValue('selfno', oSDO.getValue('selfno'));
  oViewer.setValue('gcbaselanghdl', app.sessionData.masterLanguage);
  oViewer.setValue('gclanghdl', app.sessionData.dataLanguage);
}
