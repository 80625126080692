export function PriceInfo_CurrencySelected(oView) {
  oView.getParentOfType('form').controller.dataSource.openQuery({ applyFilters: true });
}

export function PriceInfo_CutomerSelected(oView) {
  oView.getParentOfType('form').controller.dataSource.openQuery({ applyFilters: true });
}

export function PriceInfo_DateSelected(oView) {
  oView.getParentOfType('form').controller.dataSource.openQuery({ applyFilters: true });
}

export function PriceInfo_BeforeView(oView) {
  if (oView.parent.name == 'article_ofrf') {
    oView.controller.dhx.disableItem('fieldsetcalculationfilter');
    oView.controller.hideFormField('fieldsetcalculationfilter');
  }
}

export function itemBeforeFetchPriceInfo(oBE) {
  if (oBE.parent.name == 'article_ofrf') {
    const OfferHdl = oBE.container.getLink('PRIMARYSDO:TARGET').getValue('selfhdl');
    oBE.controller.setNamedQueryParam('PricingInfo', 'FilterOfferHdl', OfferHdl, 'character');

    const itemHdl = oBE.getLink('DATA:SOURCE').getValue('selfhdl');
    if (itemHdl != null)
      oBE.controller.setNamedQueryParam('PricingInfo', 'FilterItemHdl', itemHdl, 'character');

  } else {
    oBE.controller.setQueryParam({ applyFilters: true });

    const cCustomer = oBE.getLink('DISPLAY:TARGET').getValue('customerselect');
    if (cCustomer != '')
      oBE.controller.setNamedQueryParam('PricingInfo', 'FilterCustNum', cCustomer, 'character');


    const cCurrency = oBE.getLink('DISPLAY:TARGET').getValue('cCurrencyHdl');
    if (cCurrency != '')
      oBE.controller.setNamedQueryParam('PricingInfo', 'FilterCurrency', cCurrency, 'character');


    const cDate = oBE.getLink('DISPLAY:TARGET').getValue('TaxDate');
    if (cDate != '')
      oBE.controller.setNamedQueryParam('PricingInfo', 'FilterDate', cDate, 'date');


    const itemHdl = oBE.getLink('DATA:SOURCE').getValue('selfhdl');
    if (itemHdl != null)
      oBE.controller.setNamedQueryParam('PricingInfo', 'FilterItemHdl', itemHdl, 'character');

  }
}

export function diagramInit(self) {
  self.controller.parent.dhx.attachURL('/AkiomaShare/demo/addflow/samples/examples/flowchart.htm');
}

export function tranStateDialog_Init(self) {
  const oViewer = self.container.getObject('tranStateV');
  oViewer.setValue('gcbaselanghdl', app.sessionData.masterLanguage);
  oViewer.setValue('gclanghdl', app.sessionData.dataLanguage);
}

export function initTranState(self) {
  let oTree;
  const oViewer = self.container.getObject('tranStateV');
  let cStructHdl = '';

  const oSDO = self.container.caller.container.getLink('PRIMARYSDO:TARGET');
  if (isValidObj(oSDO) == false) {
    akioma.log.error(`cannot get Primary-SDO Target for: ${self.container.caller.name}`);
    return '';
  }

  let lcBoMHdl = oSDO.getValue('selfhdl');

  try {
    oTree = self.container.caller.container.getObject('itTree');
    if (oTree) {
      cStructHdl = oTree.getValue('selfhdl');
      if (isValidHdl(cStructHdl))
        lcBoMHdl = `${lcBoMHdl},${cStructHdl}`;
    }
  } catch (err) {
    akioma.log.error(`error getting Tree-Node: ${err.message}`);
  }
  oViewer.controller.parent.parent.dhx.progressOn();
  oViewer.controller.parent.dhx.progressOn();

  window.setTimeout(() => {
    const oReturn = app.controller.callServerMethod('std/tran/performTranAction.p', [
      { type: 'iCHAR', value: lcBoMHdl },
      { type: 'iCHAR', value: oViewer.getValue('gcbaselanghdl') },
      { type: 'iCHAR', value: oViewer.getValue('gclanghdl') },
      { type: 'iCHAR', value: oViewer.getValue('gctranowner') },
      { type: 'iCHAR', value: oViewer.getValue('selfdesc') },
      { type: 'iCHAR', value: oViewer.getValue('gcprintscope') },
      { type: 'iCHAR', value: 'setTranStates' },
      { type: 'oCHAR', name: 'cStatus' },
      { type: 'oCHAR', name: 'cResult' },
      { type: 'oINT', name: 'iNumRead' },
      { type: 'oINT', name: 'iNumAdded' }
    ]);

    oViewer.controller.parent.parent.dhx.progressOff();
    oViewer.controller.parent.dhx.progressOff();

    akioma.notification({
      type: 'info',
      text: `${oReturn.cStatus} ${oReturn.cResult}<br>${oReturn.iNumRead} Datensätze gelesen<br>${oReturn.iNumAdded} Datensätze geändert`,
      lifetime: -1
    });

  }, 5);
}

export function createNewMenuDef(self) {
  if (self.type == 'toolbar')
    self = self.getLink('TOOLBAR:TARGET');


  const oReturn = app.controller.callServerMethod('mod/sys/createMenuDef.p', [
    { type: 'iCHAR', value: 'createMenuDef' },
    { type: 'iTT', value: self.getAllValues() },
    { type: 'oCHAR', name: 'creturn' }
  ]);
  if (isValidHdl(oReturn.creturn) == false) {
    akioma.log.error(`Fehler in Neuanlage: ${oReturn.creturn}`);
    return;
  }

  app.controller.launchContainer({
    proc: 'menuDefW.r',
    para: `SelfHdl=${oReturn.creturn}&Page=0,1`,
    extLink: oReturn.creturn,
    self: this,
    data: true
  });

  self.container.controller.close();
}

export function createNewArticle(self) {
  if (self.type == 'toolbar')
    self = self.getLink('TOOLBAR:TARGET');


  /*
    DEFINE INPUT  PARAMETER pcItemNum AS CHARACTER NO-UNDO.
    DEFINE INPUT  PARAMETER pcItemGroup AS CHARACTER NO-UNDO.
    DEFINE INPUT  PARAMETER pcItemDesc AS CHARACTER NO-UNDO.
    DEFINE INPUT  PARAMETER pcSearch AS CHARACTER NO-UNDO.
    DEFINE INPUT  PARAMETER pcSelection AS CHARACTER NO-UNDO.
    DEFINE INPUT  PARAMETER pePrice  AS DECIMAL NO-UNDO.
  */
  const oReturn = app.controller.callServerMethod('tools/AddErpItem.p', [
    { type: 'iCHAR', value: self.getValue('artikel-nr') },
    { type: 'iCHAR', value: self.getValue('grouphdl') },
    { type: 'iCHAR', value: self.getValue('selfdesc') },
    { type: 'iCHAR', value: '' },
    { type: 'iCHAR', value: '' },
    { type: 'iDEC', value: 0 },
    { type: 'oCHAR', name: 'creturn' }
  ]);
  if (isValidHdl(oReturn.creturn) == false) {
    akioma.log.error(`Fehler in Neuanlage: ${oReturn.creturn}`);
    return;
  }

  app.controller.launchContainer({
    proc: 'articlew.r',
    para: `SelfHdl=${oReturn.creturn}&Page=0,1`,
    extLink: oReturn.creturn,
    self: this,
    data: true
  });

  self.container.controller.close();
}

export function createNewBoM(self) {
  if (self.type == 'toolbar')
    self = self.getLink('TOOLBAR:TARGET');


  const oReturn = app.controller.callServerMethod('mod/bom/createBoM.p', [
    { type: 'iCHAR', value: 'createBoM' },
    { type: 'iTT', value: self.getAllValues() },
    { type: 'oCHAR', name: 'creturn' }
  ]);
  if (isValidHdl(oReturn.creturn) == false) {
    akioma.notification({ type: 'error', text: `Fehler in Neuanlage: ${oReturn.creturn}`, expire: 5000 });
    return;
  }

  app.controller.launchContainer({
    proc: 'bomw.r',
    para: `SelfHdl=${oReturn.creturn}&Page=0,1`,
    extLink: oReturn.creturn,
    self: this,
    data: true
  });

  self.container.controller.close();
}
