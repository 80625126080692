export * as communication from './communication';
export * as contactorgf from './contactorgf';
export * as orgaw from './orgaw';

export function leadQualifyChange(oSelf) {
  const oForm = oSelf.parent;
  if (oSelf.name == 'busrelkey') {
    oForm.setValue('gcmode', 'nctc');
    oForm.setValue('gcresult', 'QP');
    oForm.setValue('state', 'H');
  }
  if (oSelf.name == 'gcresult') {
    oForm.setValue('state', 'H');
    if (oSelf.getValue == 'QN') {
      oForm.setValue('gcmode', 'close');
      oForm.setValue('statusreason', 'NI');
    } else if (oSelf.getValue == 'QP') {
      oForm.setValue('gcmode', 'close');
      oForm.setValue('statusreason', 'S');
    }
  }
}

export function qualifyLead(oSelf) {
  const oForm = oSelf.getLink('TOOLBAR:TARGET'),
    oSDO = oSelf.container.caller.parent.getLink('PRIMARYSDO:TARGET'),
    cHdl = oSDO.getValue('selfhdl'),
    cOptions = '';

  if (isValidObj(oSDO) == false) {
    akioma.log.error(`cannot get Data-Source  for: ${oSelf.name}`);
    return '';
  }

  const oReturn = app.controller.callServerMethod('mod/org/qualifyLead.p', [
    { type: 'iCHAR', value: cHdl },
    { type: 'iTT', value: oForm.getAllValues() },
    { type: 'iCHAR', value: cOptions },
    { type: 'oCHAR', name: 'cResult' }
  ]);

  akioma.messaging.info(`${'Fertig' + '</br>'}${oReturn.cResult}`);
}

export function createInExchange(self) {
  const cItemKey = self.parent.getLink('PRIMARYSDO:TARGET').getValue('selfhdl');
  akioma.message({
    text: 'U oder A?', type: 'input', inputVal: 'A', callback: options => {
      dhtmlx.confirm({
        type: 'confirm',
        text: `Datensatz ${cItemKey}<br>als neuen Datensatz an ESB übertragen?`,
        callback: function(result) {
          if (result == true) {
            const oReturn = app.controller.callServerMethod(
              'tools/createContactInExchange.p', [
                { type: 'iCHAR', value: cItemKey },
                { type: 'iCHAR', value: options },
                { type: 'oCHAR', name: 'cReturn' }
              ]);
            if (oReturn.cReturn != '') {
              try {
                akioma.showServerMessage(oReturn.cReturn, '');
              } catch (err) {
                akioma.log.error(`error displaying error: ${err.description}  ${oReturn.cReturn}`);
              }
            }
          }
        }
      });
    }
  });
}

export function importSFA(self) {
  let oSDO = null;
  let cField = 'kontonummer';

  try {
    oSDO = self.parent.getLink('PRIMARYSDO:TARGET');
  } catch (err) {
    akioma.log.error([ 'error getting SDO in importSFA', self ]);
  }

  if (oSDO == null) {
    akioma.message({ text: 'Keine gültige Kundenummer', type: 'error' });
    return;
  }

  switch (oSDO.name.toLowerCase()) {
    case 'contactd':
      cField = 'busrelkey';
      break;
  }

  const cItemKey = oSDO.getValue(cField);

  dhtmlx.confirm({
    type: 'confirm',
    text: `Kunde ${cItemKey}<br>Kontakte aus SFA aktualisieren?`,
    callback: function(result) {
      if (result == true) {
        const oReturn = app.controller.callServerMethod(
          'stubs/importSFA.p', [
            {
              type: 'iCHAR',
              value: cItemKey
            }, {
              type: 'iCHAR',
              value: ''
            }, {
              type: 'oCHAR',
              name: 'cReturn'
            }
          ]);
        if (oReturn.cReturn != '') {
          try {
            akioma.showServerMessage(oReturn.cReturn, '');
          } catch (err) {
            akioma.log.error(`error displaying error: ${err.description}  ${oReturn.cReturn}`);
          }
        }
      }
    }
  });
}

export function unchainAdr(self) {
  const oViewer = self.getLink('TABLEIO:TARGET');
  const oSDO = oViewer.parent.getLink('PRIMARYSDO:TARGET');
  const cHdl = oSDO.getValue('parenthdl');
  const cRelType = oSDO.getValue('reltype');
  const isChained = oSDO.getValue('ischained');

  if (isChained == false) {
    akioma.showMessage('Die Adresse ist bereits entkettet!', 'alert-error');
    return;
  }

  app.controller.callServerMethod('stubs/relunchain.p',
    [
      { type: 'iCHAR', value: cHdl },
      { type: 'iCHAR', value: cRelType },
      { type: 'iCHAR', value: '' }
    ]);

  oSDO.controller.reloadData();
}

export function chainAdr(self) {
  const oViewer = self.getLink('TABLEIO:TARGET');
  const oSDO = oViewer.parent.getLink('PRIMARYSDO:TARGET');
  const cHdl = oSDO.getValue('parenthdl');
  const cRelType = oSDO.getValue('reltype');

  const isChained = oSDO.getValue('ischained');
  if (isChained == true) {
    akioma.showMessage('Die Adresse ist bereits verkettet!', 'alert');
    return;
  }

  app.controller.callServerMethod('stubs/relchain.p',
    [
      { type: 'iCHAR', value: cHdl },
      { type: 'iCHAR', value: cRelType }
    ]);

  oSDO.controller.reloadData();
}

export function address__Init() {}

export function getChainState(self) {
  const oViewer = self.getLink('TABLEIO:TARGET');
  const oSDO = oViewer.getLink('DATA:SOURCE');
  oSDO.getValue('ischained');
}

export function createNewCustomer(self) {
  if (self.type == 'toolbar')
    self = self.getLink('TOOLBAR:TARGET');

  const oReturn = app.controller.callServerMethod('mod/custdf/createCustomer.p', [
    { type: 'iCHAR', value: 'createCustomer' },
    { type: 'iTT', value: self.getAllValues() },
    { type: 'oCHAR', name: 'creturn' }
  ]);
  if (isValidHdl(oReturn.creturn) == false) {
    akioma.message({ text: `Fehler in Kundenanlage: ${oReturn.creturn}`, type: 'error' });
    return;
  }

  app.controller.launchContainer({
    proc: '',
    para: `SelfHdl=${oReturn.creturn}&Page=0,1`,
    extLink: oReturn.creturn,
    self: this,
    data: true
  });

  self.container.controller.close();
}

export function commCallWeb(self) {
  let oCaller = self.parent;
  if (isValidObj(oCaller) == false) {
    akioma.log.error(`cannot get container for: ${self.name}`);
    return '';
  }

  if (oCaller.type == 'datagrid')
    oCaller = oCaller.parent;

  const oNavTarget = oCaller.getLink('PRIMARYSDO:TARGET');
  if (isValidObj(oNavTarget) == false) {
    akioma.log.error(`cannot get Primary-SDO Target for: ${oCaller.name} (${oCaller.type})`);
    return '';
  }

  const cHdl = oNavTarget.getValue('selfhdl');
  const oReturn = app.controller.callServerMethod('stubs/getwebinfo.p', [
    {
      type: 'iCHAR',
      value: cHdl
    }, {
      type: 'oCHAR',
      name: 'cWebsite'
    }
  ]);

  window.open(oReturn.cWebsite, '_blank');

  return;
}

export function commWriteMail(self) {
  let oCaller = self.parent;
  if (isValidObj(oCaller) == false) {
    akioma.log.error(`cannot get container for: ${self.name}`);
    return '';
  }

  if (oCaller.type == 'datagrid')
    oCaller = oCaller.parent;

  const oNavTarget = oCaller.getLink('PRIMARYSDO:TARGET');
  if (isValidObj(oNavTarget) == false) {
    akioma.log.error(`cannot get Primary-SDO Target for: ${oCaller.name} (${oCaller.type})`);
    return '';
  }

  const cHdl = oNavTarget.getValue('selfhdl');
  const oReturn = app.controller.callServerMethod('stubs/getmailinfo.p', [
    {
      type: 'iCHAR',
      value: cHdl
    }, {
      type: 'oCHAR',
      name: 'cMailAdr'
    }, {
      type: 'oCHAR',
      name: 'cBody'
    }
  ]);

  location.href = `mailto:${oReturn.cMailAdr}?body=${encodeURIComponent(oReturn.cBody)}`;
  return;
}

export function getDataViewTemplate() {
  return 'Nummer: #number#';
}
