export function handleRecordNotes(cHdl) {
  try {
    const oHandling = app.controller.callServerMethod('stubs/getrecordhandling.p', [
      { type: 'iCHAR', value: cHdl },
      { type: 'iCHAR', value: '' },
      { type: 'oCHAR', name: 'cAction' },
      { type: 'oCHAR', name: 'cOptions' }
    ]);

    if (oHandling.cAction == 'POPUP') {
      akioma.notification({
        type: 'info',
        text: oHandling.cOptions,
        lifetime: -1
      });
    }
  } catch (err) {
    akioma.log.error(`Error fetching record notes: ${err.description}`);
  }
}

export function openBaseContainer(self, lUsePosframe) {
  let cField = '';
  let cWindow = '';
  let cChild = '';
  let oNavTarget = null;
  let oContainer = null;

  // by default if not specified set posframe true
  if (lUsePosframe === undefined)
    lUsePosframe = true;
  if (lUsePosframe)
    oContainer = getActionContainer(self, false);
  else
    oContainer = self.container;

  switch (oContainer.name) {
    case 'article_ofrf':
    case 'article_bomf':
      cField = 'itemhdl';
      cWindow = 'articlew';
      break;
    case 'opq2w':
      oNavTarget = oContainer.getObject('dbomlines');
      cField = 'itemhdl';
      cWindow = 'itemInfoW';
      break;
    case 'grptxtofrf':
    case 'txtmodofrf':
    case 'txtmodbomf':
    case 'grptextbomf':
      cField = 'txtmodhdl';
      cWindow = 'txtmodw';
      break;
    case 'itemposofrf':
    case 'itemposbomf':
      cField = 'itemhdl';
      cWindow = 'itemcrmw';
      break;
    case 'offerw':
      cField = 'customerhdl,contacthdl';
      cWindow = 'orgaw';
      break;
    case 'linkbomf':
      cField = 'bomhdl';
      cWindow = 'bomw';
      break;
  }

  if (cField == '') {
    akioma.showServerMessage('Keine zugehörigen Stammdaten verfügbar.');
    return '';
  }

  if (isValidObj(oNavTarget) == false)
    oNavTarget = oContainer.getLink('PRIMARYSDO:TARGET');

  if (isValidObj(oNavTarget) == false) {
    akioma.log.error(`cannot get Primary-SDO Target for: ${self.name} (${self.type})`);
    return '';
  }

  let cHdl = '';
  cHdl = oNavTarget.getValue(akioma.entry(1, cField));

  if (akioma.numEntries(cField) > 1)
    cChild = oNavTarget.getValue(akioma.entry(2, cField));

  if (isValidHdl(cHdl) == false) {
    akioma.showServerMessage('Kein gültiger Stammdatensatz.');
    return;
  }

  app.controller.launchContainer({
    containerName: cWindow,
    data: true,
    extLink: cHdl,
    pages: '1,2',
    self: self,
    customData: { childHdl: cChild }
  });
}

export function WebExport(self) {
  let cHdl = '';
  let cDesc = '';
  try {
    cHdl = getSDOValue(self, 'selfhdl');
    cDesc = getSDOValue(self, 'selfdesc');
  } catch (e) {
    akioma.log.error([ 'error: ', e ]);
  }
  if (isValidHdl(cHdl) == false) {
    akioma.messaging.error('Es konnten keine zu exportierenden Daten ermittelt werden.');
    return;
  }

  dhtmlx.confirm({
    type: 'confirm',
    text: `${cDesc}<br> exportieren?`,
    callback: function(result) {
      if (result == true) {
        const oReturn = app.controller.callServerMethod(
          'stubs/exportowner.p', [
            {
              type: 'iCHAR',
              value: cHdl
            }, {
              type: 'oCHAR',
              name: 'creturn'
            }
          ]);
        akioma.messaging.info(`Export beendet. </br>${oReturn.creturn}`);
      }

    }
  });
}

export function treeFlagDlg_Init(self, cOptions) {
  if (cOptions == 'viewer')
    return;

  const oTree = self.container.caller.container.getObject('itTree');
  const cStructHdl = oTree.getValue('selfhdl');
  let i;
  const oViewer = self.getObject('TreeFlagsV');

  if (isValidHdl(cStructHdl) == false) {
    akioma.log.error(`cannot get tree node: ${self.container.caller.name}`);
    return '';
  }

  const oReturn = app.controller.callServerMethod('stubs/getTreeFlags.p', [
    {
      type: 'iCHAR',
      value: cStructHdl
    }, {
      type: 'oCHAR',
      name: 'cTreeFlags'
    }
  ]);

  const oValues = oReturn.cTreeFlags.split(' ');
  for (i in oValues) {
    if (oValues[i] && oValues[i] != '')
      oViewer.setValue(`AttributeFlags_input_${oValues[i]}`, true);
  }
}

export function TreeFlagsDialog_Init(self) {
  const structTree = self.container.caller.container.getObject('itTree');

  const GetStructFlagParameter = { plcParameter: { Value: structTree.getValue('selfhdl') } };

  akioma.invokeServerTask({
    name: structTree.controller.getOperationsEntity(),
    methodName: 'GetStructFlag',
    paramObj: GetStructFlagParameter,
    showWaitCursor: true,
    uiContext: self.container
  }).then(response => {
    const flagValue = response.plcParameter && response.plcParameter.Value || '';
    self.setValue('flag', flagValue);
  });
}

export function fileUploadComplete(self) {
  const oDataSource = self.container.caller.parent.getLink('PRIMARYSDO:TARGET').controller;
  oDataSource.reloadData();
}

export function openDocument(self) {
  const cDoc = self.getValue();
  if (cDoc)
    location.href = cDoc;
}

/**
 * Method used for refreshing data in scheduler after a record save
 * @param  {object} self The Akioma dynObject
 * @return {void}
 */
export function refreshAfterAppointmentSave(self) {
  const target = self.container.caller.getLink('PRIMARYSDO:TARGET');
  if (target)
    target.controller.openQuery({});

  self.container.controller.close();
}

/**
 * Called when an import is chosen in the GenericImportMenuW window
 * @param {object} self The akioma object
 * @return {void}
 */
export function openGenericImport(self) {
  // the dataview EventRowChosen (on dblclick) is not working. only EventRowSelected (on click) is working.
  // added temp fix that fires on dblclick.
  const onDblClick = function(self, call) {
    if (!self.clickCnt)
      self.clickCnt = 0;

    self.clickCnt++;

    if (self.clickCnt >= 2)
      call();

    setTimeout(() => {
      delete self.clickCnt;
    }, 300);
  };

  onDblClick(self, () => {
    const dataSource = self.parent.getLink('PRIMARYSDO:TARGET');
    const record = dataSource.controller.getSelectedRecord();
    const actionOptions = record.actionoptions;

    let importSettings = '';
    if (actionOptions) {
      importSettings = JSON.parse(dataSource.getValue('actionoptions').replace(/'/g, '"'));
      importSettings.guid = dataSource.getValue('functionguid');
    }

    app.controller.launchContainer({
      proc: record.actionparameter || 'GenericImportW.r',
      self: self,
      caller: self,
      allowMultipleInstances: true,
      customData: importSettings
    });
  });
}
