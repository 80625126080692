export function deleteRootNode(oSelf) {
  const oSrc = oSelf.container.getLink('PRIMARYSDO:TARGET').controller;

  akioma.message({
    type: 'confirm',
    title: 'Datensatz löschen',
    text: 'Sollen die Daten wirklich gelöscht werden?',
    callback: function(result) {
      if (result) { // set rowmod
        oSrc.setChanged(true, 'deleted');
        // update record sends record to server to execute delete
        oSrc.updateRecord({});
        oSelf.parent.controller.close();
      }
    }
  });
}
