export function sendToESB(self, oDocType) {
  // find the source SelfHdl of Master
  let oRecHdl;
  if (self.parent.name == 'offerlistw')
    oRecHdl = self.parent.controller.getDataSource().getValue('selfhdl');
  else
    oRecHdl = self.parent.controller.getDataSource().getValue('selfhdl');

  if (oDocType == '') oDocType = self.parent.name; /* z.b. offerlistw */

  akioma.invokeServerTask({
    name: 'Akioma.Crm.MasterData.General.SendToESBBT',
    methodName: 'SendDataToESB',
    paramObj: {
      plcParameter: {
        RecHdl: oRecHdl,
        DocumentType: oDocType
      }
    }
  }).then(() => {
    akioma.notification({
      type: 'info',
      text: 'Gesendet',
      lifetime: 2000
    });
  });
}

export function sync(self) {
  const cContainer = self.container.name.toLowerCase();

  if (cContainer == 'apptw') {
    const oNavTarget = self.container.getLink('PRIMARYSDO:TARGET');
    akioma.message({
      text: 'Action', type: 'input', callback: option => {
        const oReturn = app.controller.callServerMethod('stubs/sendappt2esb.p',
          [
            { type: 'iCHAR', value: oNavTarget.getValue('selfhdl') },
            { type: 'iCHAR', value: option },
            { type: 'oCHAR', name: 'cStatus' }
          ]);
        akioma.message({ text: `Status: ${oReturn.cStatus}`, type: 'error' });
      }
    });
  } else if (cContainer == 'orgaw') {
    const oTree = self.container.getObject('itTree');
    akioma.message({
      text: 'Action', type: 'input', callback: option => {
        const oReturn = app.controller.callServerMethod('stubs/sendcontact2esb.p',
          [
            { type: 'iCHAR', value: oTree.getValue('selfhdl') },
            { type: 'iCHAR', value: option },
            { type: 'oCHAR', name: 'cStatus' }
          ]);
        akioma.message({ text: `Status: ${oReturn.cStatus}`, type: 'error' });
      }
    });
  }
}
