export function createDocument(oCaller) {
  const oWindow = oCaller.screen;
  const oForm = oWindow.getChildOfType('form');
  const oTabbar = oWindow.getChildOfType('tabbar');

  const cDocNumber = oForm.getValue('docnumber');
  const cDocDescription = oForm.getValue('docdescription');
  const cDocType = oForm.getValue('doctype');

  let cTemplate = '';
  switch (oTabbar.controller.aTabKeys[oTabbar.controller.activeTabId]) {
    case 'Grid': {
      const oGrid = oTabbar.getFirstChildByType('datagrid2');
      cTemplate = oGrid.dataSource.getIndex();
      break;
    }
  }

  akioma.invokeServerTask({
    name: 'Akioma.Docs.DocumentBT',
    methodName: 'CreateDocumentDefinition',
    paramObj: {
      plcParameter: {
        DocNumber: cDocNumber,
        DocDescription: cDocDescription,
        DocType: cDocType,
        Template: cTemplate
      },
      showWaitCursor: true,
      uiContext: oWindow
    }
  }).done(oResult => {
    akioma.notification({ type: 'success', text: `New document '${oResult.plcParameter.DocNumber}' has been created successfully!` });
    oWindow.controller.close();
    akioma.launchContainer('DocMaintenance', oResult.plcParameter.DocumentHdl);
  }).fail(([ , , request ]) => {
    akioma.notification({
      type: 'error',
      text: 'Error creating new document!',
      moretext: JSON.stringify(request.response, null, 4)
    });
  });
}

export function createFromDataFile(oCaller) {
  const mergeDocsV = oCaller.parent.getObject('MergeDocsV'); // use parents instead of container for desktop windows
  const template = mergeDocsV.getObject('Template').getValue();
  const dataFileName = mergeDocsV.getObject('DataFileName').controller.getValue();
  const schemaFileName = mergeDocsV.getObject('SchemaFileName').controller.getValue();
  const outputFileName = mergeDocsV.getObject('OutputFileName').getValue();
  const saveAs = mergeDocsV.getObject('SaveAs').getValue();

  if (!mergeDocsV.controller.validate())
    return;

  akioma.invokeServerTask({
    name: 'Akioma.Docs.DocumentBT',
    methodName: 'CreateFromFile',
    showWaitCursor: true,
    uiContext: oCaller.container,
    paramObj: {
      plcParameter: {
        template: template,
        dataFileName: dataFileName,
        schemaFileName: schemaFileName,
        outputFileName: outputFileName,
        saveAs: saveAs
      }
    }
  }).done(result => {
    akioma.messaging.documentInfo(
      akioma.tran('msg.docPrinted', { defaultValue: 'Dokument wurde gedruckt' }),
      result.plcParameter.result,
      akioma.tran('msg.open', { defaultValue: 'Öffnen' }),
      'OK'
    );
  });
}
