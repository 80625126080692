export function initBizRuleDialog() {}

export function runBusinessProcess(self) {
  const oCallerWindow = self.parent.caller.parent;
  const oBE = oCallerWindow.getLink('PRIMARYSDO:TARGET');
  const oSelectedRecord = oBE.controller.getSelectedRecord(); // either offer, customer, contact

  const oForm = self.container.controller.getDescendant('form').dynObject;
  const oSelectedProcess = oForm.getField('gcprocess').controller.getSelectedRecord();
  const cComments = oForm.getField('gccomments').getValue();
  let cReferenceKey;

  // @todo SWAT-4670 leave this hardcoded for now
  switch (oBE.name) {
    case 'custd2':
      cReferenceKey = `${oSelectedRecord.kontonummer} ${oSelectedRecord.name1}`;
      break;
    case 'contactd':
      cReferenceKey = `${oSelectedRecord.orgdesc} ${oSelectedRecord.lastname}`;
      break;
    case 'offerd':
      cReferenceKey = oSelectedRecord.selfno;
      break;
  }

  if (oSelectedProcess && oSelectedProcess.misc1.toLowerCase() == 'camunda' && oSelectedProcess.misc3) {
    if (oSelectedRecord && oSelectedRecord.selfhdl && cReferenceKey) {
      akioma.invokeServerTask({
        name: 'TestCamunda-StartProcessInstance',
        methodType: 'kinvey',
        paramObj: {
          processDefinitionKey: oSelectedProcess.misc3,
          businessKey: cReferenceKey,
          variables: {
            referenceId: { value: oSelectedRecord.selfhdl, type: 'String' },
            referenceDescription: { value: cComments, type: 'String' },
            referenceKey: { value: cReferenceKey, type: 'String' }
          }
        }
      }).then(resp => {
        if (resp.status === 'success')
          akioma.notification({ type: 'info', text: `Result: ${resp.message}` });
        else
          akioma.notification({ type: 'error', text: `Result: ${resp.message}` });
      });
    } else
      akioma.notification({ type: 'error', text: 'Invalid Offer data. No process created.' });
  } else {
    const oSDO = self.container.caller.parent.getLink('PRIMARYSDO:TARGET');
    const cHdl = oSDO.getValue('selfhdl');
    const oViewer = self.getLink('TOOLBAR:TARGET');
    app.controller.callServerMethod('mod/wflow/runProcess.p', [
      { type: 'iCHAR', value: cHdl },
      { type: 'iTT', value: oViewer.getAllValues() },
      { type: 'oCHAR', name: 'cResult' }
    ]);

    const oDataSource = self.container.caller.container.getObject('OfferRoleRelationsDSO').controller;
    oDataSource.reloadData();
  }
  self.container.controller.close();
}

export function runBusinessRule(self) {
  const oSDO = self.container.caller.parent.getLink('PRIMARYSDO:TARGET');
  const cHdl = oSDO.getValue('selfhdl');
  const oViewer = self.getLink('TOOLBAR:TARGET');
  app.controller.callServerMethod('mod/wflow/runRulesheet.p', [
    { type: 'iCHAR', value: cHdl },
    { type: 'iTT', value: oViewer.getAllValues() },
    { type: 'oCHAR', name: 'cResult' }
  ]);

  const oDataSource = self.container.caller.container.getObject('OfferRoleRelationsDSO').controller;
  self.container.controller.close();
  oDataSource.reloadData();
}

export function AttributeG_Init(self) {
  const cStructHdl = getCallerValue2(self, 'selfhdl');
  const oViewer = self.getObject('vAttribute');
  const oRadioset = oViewer.getObject('powerGrid_input_');
  let i;

  if (isValidHdl(cStructHdl) == false) {
    akioma.log.error(`cannot get Parent Record: ${self.container.caller.name}`);
    return '';
  }

  const oReturn = app.controller.callServerMethod('conf/getAttributes2.p', [
    { type: 'iCHAR', value: cStructHdl },
    { type: 'oCHAR', name: 'cGrid' },
    { type: 'oTT', name: 'recordset' }
  ]);

  if (oRadioset)
    oViewer.setValue('powerGrid_input_', '61');
  else {
    for (i in oReturn.recordset)
      oViewer.setValue(`powerGrid_input_${oReturn.recordset[i].attKey}`, oReturn.recordset[i].attIsChecked);

  }

  return;
}
