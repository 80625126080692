export function buildSpeech_Init(self) {
  const form = self.container.getObject('vbuildSpeechFiles');

  // Get ProductBE
  const productBE = self.container.caller.container.getLink('PRIMARYSDO:TARGET');
  if (isNull(productBE)) {
    uiMessage.showError({ text: 'Cannot get Product Business Entity', expire: 3000 });
    return;
  }

  form.setValue('selfno', productBE.getValue('selfno'));
}

export function buildSpeechFiles(self) {
  const form = self.screen.getObject('vbuildSpeechFiles').controller;

  // Get ProductBE and set ownerHdl
  const productBE = self.screen.caller.container.getLink('PRIMARYSDO:TARGET');
  if (isNull(productBE)) {
    uiMessage.showError({ text: 'Cannot get Product Business Entity', expire: 3000 });
    return;
  }
  const ownerHdl = productBE.getValue('selfhdl');

  // Get ProductTree and set structHdl
  const productTree = self.screen.caller.container.getObject('itTree');
  if (isNull(productTree)) {
    uiMessage.showError({ text: 'Cannot get Product Tree', expire: 3000 });
    return;
  }

  // Get Form values and call BT
  const options = {};
  options['OwnerHdl'] = ownerHdl;
  options['OutputFormat'] = form.getValue('outputFormat');
  options['TextFormat'] = form.getValue('textFormat');
  options['Voice'] = form.getValue('voice');
  options['EngineType'] = form.getValue('engineType');
  options['Options'] = form.getValue('options');
  options['FilenameSuffix'] = form.getValue('filenameSuffix');

  akioma.text2Speech.buildSpeechFiles(options, akioma.swat.SwatFactory.createSwatObject(self.screen)).then(() => {
    akioma.notification({ text: 'Speech files have been generated successfully!', type: 'success' });
    self.screen.controller.close();
  }).catch(() => {
    akioma.notification({ text: 'There was an error generating the speech files!', type: 'error' });
  });
}
