function getGridNameByPage(self) {
  const cTabPage = self.container.getObject('itfolder').controller.dhx.getActiveTab();
  const iPage = parseInt(akioma.entry(akioma.numEntries(cTabPage, '_'), cTabPage, '_'));
  let cGrid = '';

  if (iPage == 1)
    cGrid = 'contactadminb';
  else
    cGrid = 'leadB';

  return cGrid;
}

export function createNewCampaign(self) {
  if (self.type == 'toolbar')
    self = self.getLink('TOOLBAR:TARGET');


  const oReturn = app.controller.callServerMethod('mod/action/createCampaign.p', [
    { type: 'iCHAR', value: 'createCampaign' },
    { type: 'iTT', value: self.getAllValues() },
    { type: 'oCHAR', name: 'creturn' }
  ]);

  if (isValidHdl(oReturn.creturn) == false) {
    akioma.log.error(`Fehler in Kampagnenanlage: ${oReturn.creturn}`);
    return;
  }

  app.controller.launchContainer({
    proc: 'campainw.r',
    para: `SelfHdl=${oReturn.creturn}&Page=0,1`,
    extLink: oReturn.creturn,
    self: this,
    data: true
  });

  self.container.controller.close();
}

export function showReceiverCount(self) {
  const oActivity = self.parent.getLink('PRIMARYSDO:TARGET');
  const cActivity = oActivity.getValue('selfhdl');

  const oReturn = app.controller.callServerMethod('mod/action/getReceiverCount.p', [
    { type: 'iCHAR', value: cActivity },
    { type: 'iCHAR', value: '' },
    { type: 'oINT', name: 'iNumTotal' },
    { type: 'oCHAR', name: 'cStatus' }
  ]);
  akioma.messaging.info (`Anzahl Empfänger für Aktivität</br>${oReturn.iNumTotal}</br>${oReturn.cStatus}`);

  return;
}

export function getParentActivity() {}

export function inheritReceivers(self) {
  const oActivity = self.container.caller.parent.getLink('PRIMARYSDO:TARGET');
  const cActivity = oActivity.getValue('selfhdl');
  const oViewer = self.container.getObject('recieverv');

  const oReturn = app.controller.callServerMethod('mod/action/inheritReceivers.p', [
    { type: 'iCHAR', value: cActivity },
    { type: 'iCHAR', value: '' },
    { type: 'iTT', value: oViewer.getAllValues() },
    { type: 'iCHAR', value: '' },
    { type: 'oINT', name: 'iNumAdded' },
    { type: 'oINT', name: 'iNumNLU' },
    { type: 'oINT', name: 'iNumDel' },
    { type: 'oINT', name: 'iNumLck' },
    { type: 'oINT', name: 'iNumDup' },
    { type: 'oCHAR', name: 'cResult' }
  ]);
  akioma.messaging.info(`${oReturn.iNumAdded} Empfänger hinzugefügt. ` + `</br>${oReturn.cResult}`);

  return true;
}

export function addContactByFilter(self, cOptions) {
  cOptions = '';

  const cGrid = getGridNameByPage (self);
  const oGrid = self.container.getObject(cGrid);
  const oActivity = self.container.caller.parent.getLink('PRIMARYSDO:TARGET');
  const cActivity = oActivity.getValue('selfhdl');
  const oFilter = oGrid.controller.getFilterArray();
  const cFields = oFilter['fields'].join(',');
  const cOperators = oFilter['operators'].join(',');
  const cValues = oFilter['values'].join(String.fromCharCode(9));

  cOptions = oGrid.getLink('DATA:SRC').name;

  const oReturn = app.controller.callServerMethod('stubs/addReceiversByFilter.p', [
    { type: 'iCHAR', value: cActivity },
    { type: 'iCHAR', value: cFields },
    { type: 'iCHAR', value: cValues },
    { type: 'iCHAR', value: cOperators },
    { type: 'iCHAR', value: cOptions },
    { type: 'oINT', name: 'iNumAdded' },
    { type: 'oINT', name: 'iNumNLU' },
    { type: 'oINT', name: 'iNumDel' },
    { type: 'oINT', name: 'iNumLck' },
    { type: 'oINT', name: 'iNumDup' },
    { type: 'oCHAR', name: 'cResult' }
  ]);

  akioma.messaging.info(`${oReturn.iNumAdded} Empfänger hinzugefügt. Ignoriert wegen` +
   `</br> Newsletter-Kz: ${oReturn.iNumNLU
   }</br> Gelöscht: ${+oReturn.iNumDel
   }</br> Gesperrt: ${+oReturn.iNumLck
   }</br> Duplikat: ${+oReturn.iNumDup
   }</br>${oReturn.cResult}`);
}

export function doMailing() {}

export function removeContactByFilter(self, cOptions) {
  const cGrid = getGridNameByPage (self);
  const oGrid = self.container.getObject(cGrid);
  const oActivity = self.container.caller.parent.getLink('PRIMARYSDO:TARGET');
  const cActivity = oActivity.getValue('selfhdl');
  const oFilter = oGrid.controller.getFilterArray();
  const cFields = oFilter['fields'].join(',');
  const cOperators = oFilter['operators'].join(',');
  const cValues = oFilter['values'].join(String.fromCharCode(9));

  cOptions = oGrid.getLink('DATA:SRC').name;
  const oReturn = app.controller.callServerMethod('stubs/removeReceiversByFilter.p', [
    { type: 'iCHAR', value: cActivity },
    { type: 'iCHAR', value: cFields },
    { type: 'iCHAR', value: cValues },
    { type: 'iCHAR', value: cOperators },
    { type: 'iCHAR', value: cOptions },
    { type: 'oINT', name: 'iNumRemoved' },
    { type: 'oCHAR', name: 'cResult' }
  ]);

  akioma.messaging.info(`Es wurden ${oReturn.iNumRemoved}</br>Empfänger entfernt</br>${oReturn.cResult}`);
}

export function addContactSelected(self) {
  const cGrid = getGridNameByPage (self);
  const oGrid = self.container.getObject(cGrid);
  const oActivity = self.container.caller.parent.getLink('PRIMARYSDO:TARGET');
  const cActivity = oActivity.getValue('selfhdl');

  const oSDO = oGrid.getLink('DATA:SOURCE');
  if (isValidObj(oSDO) == false) {
    akioma.log.error(`cannot get Data-Source  for: ${oGrid.name}`);
    return '';
  }

  const lcHdl = oSDO.getValue('selfhdl');

  const oReturn = app.controller.callServerMethod('stubs/addReceiversByList.p', [
    { type: 'iCHAR', value: cActivity },
    { type: 'iCHAR', value: lcHdl },
    { type: 'iCHAR', value: cGrid },
    { type: 'oINT', name: 'iNumAdded' },
    { type: 'oCHAR', name: 'cResult' }
  ]);

  akioma.messaging.info(`${oReturn.iNumAdded} Empfänger hinzugefügt.` + `</br>${oReturn.cResult}`);
}
