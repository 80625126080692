export function createNewERPItem(self) {
  if (self.type == 'toolbar')
    self = self.getLink('TOOLBAR:TARGET');


  const oReturn = app.controller.callServerMethod('mod/ppsitm/createERPItem.p', [
    { type: 'iCHAR', value: 'createERPItem' },
    { type: 'iTT', value: self.getAllValues() },
    { type: 'oCHAR', name: 'creturn' }
  ]);
  if (isValidHdl(oReturn.creturn) == false) {
    akioma.log.error(`Fehler in Artikelanlage: ${oReturn.creturn}`);
    return;
  }

  app.controller.launchContainer({
    proc: 'articlew.r',
    para: `SelfHdl=${oReturn.creturn}&Page=0,1`,
    extLink: oReturn.creturn,
    self: this,
    data: true
  });

  self.container.controller.close();
}

export function importOV3(self, cOptions) {
  const cItemKey = getSDOValue(self, 'artikel-nr');

  dhtmlx.confirm({
    type: 'confirm',
    text: `Artikel ${cItemKey}<br>Texte und Bilder aus ov3 aktualisieren?`,
    callback: function(result) {
      if (result == true) {
        app.controller.callServerMethod(
          'stubs/importov3.p', [
            {
              type: 'iCHAR',
              value: cItemKey
            }, {
              type: 'iCHAR',
              value: cOptions
            }, {
              type: 'oCHAR',
              name: 'creturn'
            }
          ]);
      }
    }
  });
}

/**
 * Client side code executed on initialize event for ckeditor and remove title
 * @param {object} self akioma object
 */
export function ckeditorRemoveTitleOnInit(self) {
  self.controller.removeTitle();
}

export function doCalc(oSelf) {
  calculateAmount(oSelf, '');
}

function calculateAmount(oSelf) {
  const oForm = oSelf.getFirstParentByType('form').dynObject,
    oPrice = oForm.getField('price'),
    oQuantity = oForm.getField('quantity'),
    oAmount = oForm.getField('amount'),
    oAmountGross = oForm.getField('amountgross'),
    oDiscount = oForm.getField('discountamount'),
    oDiscountPercentage = oForm.getField('discountpercentage');

  const eAmountGross = oQuantity.getValue() * oPrice.getValue();
  if (eAmountGross == 0 || isNaN(eAmountGross))
    akioma.log.error('gross amount = 0 ?!?!?');

  oAmountGross.setValue('', eAmountGross);
  oDiscount.setValue('', oAmountGross.getValue() * (oDiscountPercentage.getValue() / 100));
  oAmount.setValue('', oAmountGross.getValue() - oDiscount.getValue());
}
