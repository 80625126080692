export function onInitializeForm(oSelf) {
  oSelf.setValue('strmultiplyqty', 1);
}

export function onAfterDisplayForm(oSelf) {
  if (oSelf.container.controller.userData && oSelf.container.controller.userData.opportunity && oSelf.container.controller.userData.opportunity != null)
    oSelf.setValue('GCOptions', `Opportunity=${oSelf.container.controller.userData.opportunity}`);
}

export function onValidateCustomerDesc(oSelf) {
  const oForm = oSelf.getParentOfType('form');
  const oContactDynselect = oForm.getObject('contactdesc');
  oContactDynselect.controller.setValue('');
  oForm.controller.dataSource.setFieldValue({ name: 'contacthdl', value: '' });
  oForm.controller.dataSource.setFieldValue({ name: 'contactdesc', value: '' });
  getInitialValues(oForm);
}

export function onBeforeFecthContactDesc(oSelf) {
  const oForm = akioma.getForm(oSelf.controller);
  const customerHdl = oForm.dataSource.getFieldValue('selfhdl-3');
  if (isValidHdl(customerHdl))
    oSelf.controller.businessEntity.query.addUniqueCondition('busrelhdl', '=', customerHdl);

}

export function onBeforeSaveDataSource(oSelf) {
  const oForm = oSelf.getLink('DISPLAY:TARGET');
  const options = {};

  options.gcmode = oForm.getValue('gcmode');
  options.strrecalcprices = oForm.getValue('strrecalcprices');
  options.strincludeinsum = oForm.getValue('strincludeinsum');
  options.cpoptqtymode = oForm.getValue('cpoptqtymode');
  options.gcoptions = oForm.getValue('GCOptions');
  options.strmultiplyqty = oForm.getValue('strmultiplyqty');

  oSelf.controller.setFieldValue({ name: 'akuioptions', value: JSON.stringify(options) });
}

export function onValidateSourceOfferDynselect(oSelf) {
  getInitialValues(oSelf.getParentOfType('form'));
}

export function saveRecord(oSelf) {
  const oWin = oSelf.container;
  const oForm = oWin.controller.getDescendant('form');
  const cMode = oForm.getValue('gcmode');

  akioma.saveRecord (oForm.dynObject);
  oForm.dataSource.fireAfterUpdate(function(jsdo, success, request, data) {
    if (success) {
      oWin.controller.close();

      const oPromiseLaunch = app.controller.launchContainer({
        proc: 'offerw.r',
        para: 'Page=0,1,2',
        // repositionTo //after rendering check PRIMARYSDO and position
        repositionTo: data.selfhdl,
        async: true,
        data: true,
        activation: true,
        extLink: '',
        self: this
      });

      oPromiseLaunch.done(function(oNewContainer) {
        const cSourceHdl = data.smartcopiedfrom;

        if (cMode == 'CM' || cMode == 'CM2') {
          oNewContainer.posWindow('right');
          app.controller.launchContainer({
            extLink: cSourceHdl,
            position: 'fitToLeft',
            self: this,
            data: true
          });
        } else if (cMode == 'CMR') {
          oNewContainer.posWindow('left');
          app.controller.launchContainer({
            extLink: cSourceHdl,
            position: 'fitToRight',
            self: this,
            data: true
          });
        }
      });
    }
  });
}

export function getInitialValues(oForm) {
  const oBE = oForm.controller.dataSource;
  let sKeys = '',
    sValues = '';

  /* set customer and contact dynselect as modified to keep selection */
  oForm.controller._dispatch('setFormFieldDirty', { hasChanges: true, id: oForm.getObject('customerdesc').controller.opt.id });
  oForm.controller._dispatch('setFormFieldDirty', { hasChanges: true, id: oForm.getObject('contactdesc').controller.opt.id });
  oForm.controller._dispatch('setFormFieldDirty', { hasChanges: true, id: oForm.getObject('salesrephdl').controller.opt.id });
  oForm.controller._dispatch('setFormFieldDirty', { hasChanges: true, id: oForm.getObject('destcountryhdl').controller.opt.id });
  oForm.controller._dispatch('setFormFieldDirty', { hasChanges: true, id: oForm.getObject('currencydesc').controller.opt.id });
  oForm.controller._dispatch('setFormFieldDirty', { hasChanges: true, id: oForm.getObject('pricegroup').controller.opt.id });
  oForm.controller._dispatch('setFormFieldDirty', { hasChanges: true, id: oForm.getObject('pricefactor').controller.opt.id });
  if (oForm.getValue('sourcedocument') && oForm.getValue('sourcedocument') != '') {
    oForm.controller._dispatch('setFormFieldDirty', { hasChanges: true, id: oForm.getObject('sourcedocument').controller.opt.id });
    sKeys = 'SourceHdl';
    sValues = oForm.getValue('sourcedocument');
  }

  const oChangedFields = oForm.controller.getFieldsHasChanges();
  for (const i in oChangedFields) {
    const key = oChangedFields[i];
    const oField = oForm.getObject(key).controller;
    const cKeyField = oField.opt.LookupKeyValueBinding;
    if (oField.dynObject.type == 'dynselect' && cKeyField !== '_self') {
      sKeys = `${sKeys},${oField.opt.LookupKeyValueBinding}`;
      sValues = `${sValues},${oField.getValue()}`;
    } else {
      let cValue = oForm.getValue(key);
      if (oField.view == 'calendar') /* workaround for date format inconsistency */
        cValue = oField.dhx.oElement.value;

      sKeys = `${sKeys},${key}`;
      sValues = `${sValues},${cValue}`;
    }
  }
  sKeys = sKeys.trim(',');
  sValues = sValues.trim(',');
  oBE.setForeignKeys([{ name: sKeys, value: sValues }]);
  oBE.eventAfterAddInitValues(oBE.dhx.getCursor());

  if (oForm.getValue('selfno') && oForm.getValue('selfno') != '' && oChangedFields.indexOf('selfno') == -1)
    oForm.setValue('selfno', `${oForm.getValue('selfno')}-2`);
}
