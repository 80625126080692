export const pricingOptions = {
  handling: 'NORM',
  numLic: 0,
  hub: '',
  iCode1: '',
  iCode2: ''
};

export function roundingLN(eValue) {
  let eResult = 0.0;
  try {
    eResult = roundToDecimal(eValue, (eValue < 50) ? 1 : 0);
  } catch (e) {
    akioma.log.error([ `Error in LN rounding:${e.message}`, eValue, e ]);
  }

  if (!eResult)
    eResult = 0;

  return eResult;
}

export function getMyValue(oSelf, cFieldName) {
  const oCaller = oSelf.container;
  if (isValidObj(oCaller) == false) {
    akioma.log.error(`cannot get container for: ${oSelf.name}`);
    return '';
  }

  const oNavTarget = oCaller.getLink('PRIMARYSDO:TARGET');
  if (isValidObj(oNavTarget) == false) {
    akioma.log.error(`cannot get Primary-SDO Target for: ${oCaller.name} (${oCaller.type})`);
    return '';
  }

  return oNavTarget.getValue(cFieldName);
}

function calculatePrice(oSelf) {
  let oForm = oSelf;
  if (oForm.type != 'form')
    oForm = oForm.parent;

  const oPrice = oForm.getField('pricegross');
  const oPriceFC = oForm.getField('pricegrossfc');
  const oDiscountPct = oForm.getField('discountpercentage');

  let cItemKey = '';
  const cDelim = String.fromCharCode(9);
  let iPriceGroup = oForm.getLink('DISPLAY:SRC').getValue('pricegroup');
  const iCostGroup = 0;

  const oListPrice = oForm.getField('listprice');
  const oListPriceFC = oForm.getField('listpricefc');

  const lcOfferHdl = getMyValue(oForm, 'selfhdl');
  akioma.log.info([ 'calcPrice offerHdl: ', lcOfferHdl ]);

  if (!iPriceGroup)
    iPriceGroup = 0;

  cItemKey = oForm.getField('itemhdl').getValue();
  if (pricingOptions.handling != 'NORM')
    cItemKey += cDelim + pricingOptions.hub + cDelim + pricingOptions.iCode1 + cDelim + pricingOptions.iCode2;


  // use BusinessTask for calculating prices
  akioma.invokeServerTask({
    name: 'Akioma.Crm.PricingBT',
    methodName: 'GetSalesPrice',
    paramObj: {
      plcParameter: {
        OfferHdl: lcOfferHdl,
        ArticleNo: cItemKey,
        PriceGroup: Number(iPriceGroup),
        CostGroup: Number(iCostGroup),
        Qty: oForm.getField('quantity').getValue()
      }
    }
  }).done(oResult => {
    const oReturn = oResult.plcParameter;
    app.sessionData.lastQuote = oForm.name;

    oPrice.setValue(oReturn.Price);
    oPriceFC.setValue(oReturn.PriceFC);
    if (!oForm.controller.oVuexState.attributes.hasChanges)
      oForm.controller._dispatch('incrementHasChanges', 1);

    oForm.controller._dispatch('setHasChanges', true);


    oForm.controller._dispatch('setFormFieldDirty', { hasChanges: true, id: oPrice.controller.opt.id });

    oForm.controller._dispatch('setFormFieldDirty', { hasChanges: true, id: oPriceFC.controller.opt.id });

    if (oDiscountPct && oDiscountPct.getValue() == 0) {
      oDiscountPct.setValue(oReturn.LineDiscount);
      oForm.controller._dispatch('setFormFieldDirty', { hasChanges: true, id: oDiscountPct.controller.opt.id });
    }
    oForm.getField('taxrate').controller.dynSelectControl.positionToRecord([{ 'prozent': oReturn.TaxRate }]);
    oForm.controller._dispatch('setFormFieldDirty', { hasChanges: true, id: oForm.getField('taxrate').controller.opt.id });

    if (oListPrice)
      oListPrice.setValue(oReturn.ListPrice);
    if (oListPriceFC)
      oListPriceFC.setValue(oReturn.ListPriceFC);

    try {
      if (oForm.name != 'itemposofr_basedatav') {
        oForm.getField('prodcosts').setValue(oReturn.ProdCosts);
        oForm.getField('prodcostsamount').setValue(oReturn.ProdCosts * oForm.getField('quantity').getValue());

        oForm.controller._dispatch('setFormFieldDirty', { hasChanges: true, id: oForm.getField('prodcosts').controller.opt.id });
        oForm.controller._dispatch('setFormFieldDirty', { hasChanges: true, id: oForm.getField('prodcostsamount').controller.opt.id });
      }
      calculateAmount(oPrice, 'noFC');
    } catch (e) {
      akioma.log.error([ `Error calling calcAmount:${e.message}`, oSelf, e ]);
    }
  });

}

export function getPrice(self, cOptions, cItem) {
  let oForm = self;
  if (oForm.type == 'toolbar' || oForm.type == 'ribbon') {
    oForm = getActionContainer(oForm, false);
    oForm = oForm.getLink('PRIMARYSDO:TARGET');
    if (isValidObj(oForm) == false) {
      akioma.log.error(`cannot get Primary-SDO Target for: ${oForm.name} (${self.type})`);
      return '';
    }
    oForm = oForm.getLink('DISPLAY:TARGET');
  }

  if (!oForm) {
    akioma.log.error('cannot get Form');
    return;
  }

  if (!cItem)
    cItem = oForm.getValue('itemhdl');

  const oHandling = app.controller.callServerMethod('stubs/getitemhandling.p', [
    { type: 'iCHAR', value: cItem },
    { type: 'iCHAR', value: 'ERPItem' },
    { type: 'oCHAR', name: 'cReturn' }
  ]);


  pricingOptions.handling = oHandling.cReturn;

  const callCalculatePrice = () => {
    try {
      calculatePrice(oForm, cOptions);
    } catch (e) {
      akioma.log.error([ `Error calling calcPrice: ${e.message}`, self, e ]);
    }
  };

  switch (akioma.entry(1, pricingOptions.handling, ':')) {
    case 'CONFIG':
      try {
        const cString = akioma.entry(3, pricingOptions.handling, ':');
        pricingOptions.numLic = oForm.getValue('subqty');
        akioma.message({
          text: 'Anzahl Lizenzen:', type: 'input', inputVal: pricingOptions.numLic, callback: numLic => {
            pricingOptions.numLic = numLic;
            pricingOptions.hub = akioma.stringReplace(cString, '#Lic#', pricingOptions.numLic);
            oForm.setValue('subqty', pricingOptions.numLic);
            callCalculatePrice();
          }
        });
      } catch (err) {
        akioma.log.info(`error getting pricing options: ${err.description}`);
      }
      break;
    case 'CONF':
      try {
        oForm.setValue('subqty', 0);
        pricingOptions.numLic = 0;
        pricingOptions.hub = '';

        pricingOptions.iCode1 = oForm.getValue('pricinginfo1');
        pricingOptions.iCode2 = oForm.getValue('pricinginfo2');
      } catch (err) {
        akioma.log.info(`error getting pricing options: ${err.description}`);
      }

      akioma.message({
        text: 'Identcode1:', type: 'input', callback: code1 => {
          pricingOptions.iCode1 = code1;
          oForm.setValue('pricinginfo1', pricingOptions.iCode1);
          akioma.message({
            text: 'Identcode2:', type: 'input', callback: code2 => {
              pricingOptions.iCode2 = code2;
              oForm.setValue('pricinginfo2', pricingOptions.iCode2);
              callCalculatePrice();
            }
          });
        }
      });
      break;
    case 'LIC':
      pricingOptions.numLic = oForm.getValue('subqty');
      akioma.message({
        text: 'Anzahl Lizenzen:', type: 'input', inputVal: pricingOptions.numLic, callback: numLic => {
          pricingOptions.numLic = numLic;
          pricingOptions.hub = `LICENCES_${pricingOptions.numLic}PCS`;
          oForm.setValue('subqty', pricingOptions.numLic);
          callCalculatePrice();
        }
      });
      break;
    case 'HUB':
      pricingOptions.numLic = oForm.getValue('subqty');
      akioma.message({
        text: 'Länge:', type: 'input', inputVal: pricingOptions.numLic, callback: numLic => {
          pricingOptions.numLic = numLic;
          pricingOptions.hub = `${pricingOptions.numLic}`;
          oForm.setValue('subqty', pricingOptions.numLic);
          callCalculatePrice();
        }
      });
      break;
    default:
      callCalculatePrice();
      break;
  }
}

export function calculateAmount(oSelf, cOptions) {
  const oForm = akioma.getForm(oSelf.controller).dynObject;
  const formControl = oForm.controller;
  const oPrice = oForm.getField('price');
  const oPriceGross = oForm.getField('pricegross');
  const oPriceGrossFC = oForm.getField('pricegrossfc');
  const oPriceFC = oForm.getField('pricefc');
  const oAmount = oForm.getField('amount');
  const oAmountFC = oForm.getField('amountfc');
  const oDiscount = oForm.getField('discountamount');
  const oDiscountFC = oForm.getField('discountamountfc');
  const oDiscountPercentage = oForm.getField('discountpercentage');

  let oQuantity = oForm.getField('quantity');
  if (isNull(oQuantity)) // temporary workaround because in grouppos the field is named wrong...
    oQuantity = oForm.getField('qty');
  akioma.log.info([ ' .. calcAmount: ', oAmount, oPrice, oQuantity, oDiscountPercentage, oDiscount ]);

  let ePrice = 0;
  let ePriceFC = 0;
  let ePriceDiscount = 0;
  let ePriceDiscountFC = 0;

  if (oSelf.name.toLowerCase() == 'quantity') {
    /* this code is also called in dummyofr_basedatav, but a price can only be fetched for erp items  */
    if (oSelf.parent.name == 'articleofr_basedatav' && app.sessionData.customization == 'FESTO')
      getPrice (oSelf.parent, ''); /* if quantity changed, the price might change */
  }

  if (cOptions != 'noFC') {
    if (oSelf.name.toLowerCase() == 'pricegross') {
      const eValue = oPriceGross.getValue();
      const cCurrency = getMyValue(oSelf, 'currencyhdl');
      const oReturn = akioma.callServerMethod('stubs/convtocurrency.p', [
        { type: 'iCHAR', value: cCurrency },
        { type: 'iDEC', value: 0 },
        { type: 'iDEC', value: 0 },
        { type: 'iDEC', value: eValue },
        { type: 'oDEC', name: 'eConverted' }
      ]);
      oPriceGrossFC.setValue('', oReturn.eConverted);

    }
    if (oSelf.name.toLowerCase() == 'pricegrossfc') {
      const eValue = oPriceGrossFC.getValue();
      const cCurrency = getMyValue(oSelf, 'currencyhdl');
      const oReturn = akioma.callServerMethod('stubs/convfromcurrency.p', [
        { type: 'iCHAR', value: cCurrency },
        { type: 'iDEC', value: 0 },
        { type: 'iDEC', value: 0 },
        { type: 'iDEC', value: eValue },
        { type: 'oDEC', name: 'eConverted' }
      ]);
      oPriceGross.setValue('', oReturn.eConverted);
    }
  }

  ePriceDiscount = Number (oPriceGross.getValue()) * (oDiscountPercentage.getValue() / 100);
  ePriceDiscountFC = Number(oPriceGrossFC.getValue()) * (Number(oDiscountPercentage.getValue()) / 100);
  akioma.log.info([ ' priceDiscount', ePriceDiscount, oPrice, oQuantity, oDiscountPercentage, oDiscount ]);

  ePrice = Number(oPriceGross.getValue()) + ePriceDiscount;
  ePriceFC = Number(oPriceGrossFC.getValue()) + ePriceDiscountFC;

  if (app.sessionData.customization == 'LN') {
    ePrice = roundingLN(ePrice);
    ePriceFC = roundingLN(ePriceFC);
    akioma.log.info([ 'rounded to 1 decimal:', ePrice, ePriceFC ]);
  }

  oPrice.setValue('', ePrice);
  oPriceFC.setValue('', ePriceFC);
  oForm.controller._dispatch('setFormFieldDirty', { hasChanges: true, id: oPrice.controller.opt.id });
  oForm.controller._dispatch('setFormFieldDirty', { hasChanges: true, id: oPriceFC.controller.opt.id });

  const eAmount = Number(oQuantity.getValue()) * Number(oPrice.getValue());
  const eAmountFC = Number(oQuantity.getValue()) * Number(oPriceFC.getValue());
  const oAmountGross = oForm.getField('amountgross');
  const oAmountGrossFC = oForm.getField('amountgrossfc');

  if (!isNull(oAmountGross))
    oAmountGross.setValue('', Number(oQuantity.getValue()) * oPriceGross.getValue());
  if (!isNull(oAmountGrossFC))
    oAmountGrossFC.setValue('', Number(oQuantity.getValue()) * oPriceGrossFC.getValue());

  akioma.log.info([ 'Values ', oAmount.getValue(), oPrice.getValue(), oQuantity.getValue(), oDiscountPercentage.getValue() ]);
  akioma.log.info([ 'Percentage: ', 1 * (oDiscountPercentage.getValue() / 100) ]);

  oDiscount.setValue('', ePriceDiscount);
  oDiscountFC.setValue('', ePriceDiscountFC);
  oAmount.setValue('', eAmount);
  oAmountFC.setValue('', eAmountFC);
  formControl._dispatch('setFormFieldDirty', { hasChanges: true, id: oDiscount.controller.opt.id });
  formControl._dispatch('setFormFieldDirty', { hasChanges: true, id: oDiscountFC.controller.opt.id });
  formControl._dispatch('setFormFieldDirty', { hasChanges: true, id: oAmount.controller.opt.id });
  formControl._dispatch('setFormFieldDirty', { hasChanges: true, id: oAmountFC.controller.opt.id });

  if (oSelf.parent.name == 'dummyofr_basedatav') {
    akioma.log.info([ 'calling calcAmount for dummyPos ', oSelf, oSelf.name ]);
    if (oSelf.name.toLowerCase() == 'pricegross') {
      const oTargetMargin = oForm.getField('targetconmargin');
      const oProdCosts = oForm.getField('prodcosts'); /* prodCosts / purchase price */

      oTargetMargin.setValue(100 - (Number(oProdCosts.getValue()) * 100 / oPrice.getValue()));
      formControl._dispatch('setFormFieldDirty', { hasChanges: true, id: oTargetMargin.controller.opt.id });
    }
  }
}

export function calculateProdCosts(oSelf) {
  const oForm = akioma.getForm(oSelf.controller).dynObject;
  const oVendorListPrice = oForm.getField('vendorlistprice');
  const oTargetMargin = oForm.getField('targetconmargin');
  const oProdCosts = oForm.getField('prodcosts'); /* prodCosts / purchase price */
  const oPrice = oForm.getField('pricegross');
  const oVendorDiscountPercentage = oForm.getField('vendordiscount');

  let eVendorDiscount = 0;
  let eTargetPrice = 0;

  if (oVendorListPrice.getValue() == 0)
    return;

  eVendorDiscount = oVendorListPrice.getValue() * (oVendorDiscountPercentage.getValue() / 100);
  oProdCosts.setValue('', oVendorListPrice.getValue() + eVendorDiscount);
  akioma.log.info([ 'discount:', eVendorDiscount ]);

  eTargetPrice = (oProdCosts.getValue() * 100) / (100 - oTargetMargin.getValue());
  akioma.log.info([ 'targetPrice:', eTargetPrice ]);
  if (app.sessionData.customization == 'LN' && oSelf.parent.name == 'dummyofr_basedatav') {
    eTargetPrice = roundingLN(eTargetPrice);
    akioma.log.info([ 'applied LN rounding:', eTargetPrice ]);
  }
  oPrice.setValue('', eTargetPrice);
  akioma.log.info([ 'targetprice:', eTargetPrice ]);

  calculateAmount(oPrice, '');

  return;
}
