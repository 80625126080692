let executed = null;

export function launchDataSourcesW(self) {
  if (executed)
    return;

  if (!self)
    return;

  app.controller.launchContainer({
    containerName: 'chooseFileG',
    self: self,
    caller: self,
    customData: {
      groupHdl: 'DataFile',
      callback: '$ crm.datasources.dataFileChosen(self);'
    },

    target: self.controller,
    dynGuid: window.dhtmlx.uid(),
    containerinsguid: self.controller.opt._ContainerInstanceGuid
  });

  executed = true;
}

export function dataFileChosen(self) {
  const chooseFileG = self.container;
  const dFileList = chooseFileG.getObject('dFileList');
  const filename = dFileList.getValue('clientfilename');

  const caller = chooseFileG.controller.caller;
  const mergeDocsW = caller.parent.parent.parent.dynObject;
  const dataFileName = mergeDocsW.getObject('datafilename');

  dataFileName.setValue(filename);

  chooseFileG.controller.close();
}
