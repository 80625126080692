import * as bom from './bom';
import * as bpm from './bpm';
import * as campaign from './campaign';
import * as datasources from './datasources';
import * as docs from './docs';
import * as esb from './esb';
import * as events from './events';
import * as global from './global';
import * as item from './item';
import * as logic from './logic';
import * as offer from './offer';
import * as opq from './opq';
import * as org from './org';
import * as speech from './speech';
import * as text from './text';
import * as tran from './tran';
import * as version from './version';

// Register 'crm.events' as global events domain
akioma.events.domains.unshift('crm.events');

// Enable refresh scheme globally for all BEs
akioma.enableGlobalRefresh();

// Export subdomains
export default { bom, bpm, campaign, datasources, docs, esb, events, global, item, logic, offer, opq, org, speech, text, tran, version };
